import styled from '@emotion/styled';
import { Colors, Heading } from '@robinpowered/design-system';

export const Row = ({
  title,
  children,
}: {
  title: string;
  children: React.ReactNode;
}) => {
  return (
    <Wrapper>
      <Header>{title}</Header>
      <RowDetail>{children}</RowDetail>
    </Wrapper>
  );
};

const Header = styled(Heading.Small)`
  min-width: 125px;
  color: ${Colors.Gray60};
`;

const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 8px;
`;

const RowDetail = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
