import React, { createContext, useCallback, useContext } from 'react';
import {
  UITicketQuestion,
  UITicketQuestionOptionInput,
  isMenuQuestionType,
  isMultiChoiceQuestionType,
} from 'types';
import { MeetingServiceType } from 'components/ServiceForm/ServiceFormContainer';
import { useFormContext } from 'react-hook-form';

type QuestionBuilderType = {
  question?: UITicketQuestion;
  onUpdateOptions: (
    options: UITicketQuestionOptionInput[],
    hasEmptyContent?: boolean
  ) => void;
  getUpdatedOptions: (
    option: UITicketQuestionOptionInput,
    index: number
  ) => UITicketQuestionOptionInput[];
  onDeleteOption: (option: UITicketQuestionOptionInput) => void;
};

const QuestionBuilderContext = createContext<QuestionBuilderType>({
  question: undefined,
  onUpdateOptions: () => null,
  getUpdatedOptions: () => [],
  onDeleteOption: () => null,
});

export const QuestionBuilderProvider = ({
  children,
  question,
}: {
  children: JSX.Element;
  question: UITicketQuestion;
}): JSX.Element => {
  const { setValue, getValues } = useFormContext<MeetingServiceType>();

  const onUpdateOptions = useCallback(
    (options: UITicketQuestionOptionInput[]) => {
      const hasEmptyContent =
        options.some((option) => !option.name.trim().length) ||
        !question.prompt.trim().length;

      const updatedQuestion = {
        ...question,
        hasEmptyContent,
        options,
      } as UITicketQuestion;

      const updatedQuestionMap = structuredClone(getValues('questions'));
      updatedQuestionMap.set(question.id, updatedQuestion);
      setValue('questions', updatedQuestionMap);
    },
    [question, getValues, setValue]
  );

  const onDeleteOption = useCallback(
    (option: UITicketQuestionOptionInput) => {
      if (!isMultiChoiceQuestionType(question)) return null;

      const options = isMenuQuestionType(question)
        ? question.options.filter((o) => o.id !== option.id)
        : question.options.filter((o) => o.id !== option.id);

      onUpdateOptions(options);
    },
    [onUpdateOptions, question]
  );

  const getUpdatedOptions = useCallback(
    (
      option: UITicketQuestionOptionInput,
      index: number
    ): UITicketQuestionOptionInput[] => {
      if (!isMultiChoiceQuestionType(question)) return [];
      // Find the option in the array and replace it with whatever
      // its new values are
      const newArray = [...question.options];
      // Find the option in the array and replace it
      newArray.splice(index, 1, option);
      return newArray;
    },
    [question]
  );

  return (
    <QuestionBuilderContext.Provider
      value={{
        question,
        onUpdateOptions,
        getUpdatedOptions,
        onDeleteOption,
      }}
    >
      {children}
    </QuestionBuilderContext.Provider>
  );
};

export const useQuestionBuilderContext = (): QuestionBuilderType => {
  return useContext(QuestionBuilderContext);
};
