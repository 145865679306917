import { Grid, Layout } from '@robinpowered/ui-kit';
import {
  ServiceDetailsSidebarContextProvider,
  useServiceDetailsSidebarContext,
} from './contexts/ServiceDetailsSidebarContext';
import styled from '@emotion/styled';
import { Header } from './components/Header';
import { Body } from './components/Body';
import { Footer } from './components/Footer';
import { useMemo } from 'react';
import { MobileDrawer } from './components/MobileDrawer';
import { useFocusTrap } from '@robinpowered/design-system';

const ServiceDetailsSidebarWrapper = () => {
  const { Sider } = Layout;
  const { useBreakpoint } = Grid;
  const { lg } = useBreakpoint();
  const { serviceRequestKey } = useServiceDetailsSidebarContext();
  // Whether or not the sidebar is open/closed
  const collapsed = useMemo(() => !serviceRequestKey, [serviceRequestKey]);
  const focusTrap = useFocusTrap<HTMLDivElement>();

  return (
    <>
      {lg ? (
        <div ref={focusTrap} className="focus-trap">
          <Sider
            style={{ height: 'calc(100vh - 56px)' }}
            width={520}
            collapsedWidth={0}
            collapsed={collapsed}
            theme="light"
          >
            <Wrapper>
              <Header />
              <Body />
              <Footer />
            </Wrapper>
          </Sider>
        </div>
      ) : (
        <MobileDrawer />
      )}
    </>
  );
};

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const ServiceDetailsSidebar = () => {
  return (
    <ServiceDetailsSidebarContextProvider>
      <ServiceDetailsSidebarWrapper />
    </ServiceDetailsSidebarContextProvider>
  );
};
