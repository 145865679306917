import {
  WorkplaceServiceFormRequest,
  WorkplaceServiceRequestParentAPI,
  useManageChildPenpalMessages,
} from '@robinpowered/common-lib';
import { useChangeAnswersOnMeetingServiceRequestMutation } from 'generated';
import { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Answers,
  GetMeetingServiceByIdForServiceRequestQuestions,
} from '../../types/ServiceRequestTypes';
import { debounce } from 'lodash';
import { useAmplitude } from 'contexts';
import { AmplitudeEvents } from 'types';
import { mapAnswersToRequest } from 'components/ServiceRequest/utils';

type Options = {
  meetingServiceQuestions:
    | GetMeetingServiceByIdForServiceRequestQuestions
    | undefined;
  previousCompletedFormId: string | undefined;
  answers: Answers;
  totalPrice: string | undefined;
};

export const useConnectToParent = (
  options: Options,
  isDataValid: () => boolean
) => {
  const { t } = useTranslation('MeetingServiceRequest');
  const { trackEvent, flushEvents } = useAmplitude();
  const [initialValues, setInitialValues] =
    useState<WorkplaceServiceFormRequest | null>(null);

  // Add edit meeting service for draft/existing
  const [changeAnswersOnMeetingServiceRequest] =
    useChangeAnswersOnMeetingServiceRequestMutation();

  const changeMeetingServiceRequestAnswers = useCallback(async () => {
    try {
      if (initialValues && initialValues.formAction?.serviceRequestId) {
        // Validate info and prevent request if it fails
        if (!isDataValid()) {
          throw t(`errors.default`);
        }

        const value = await changeAnswersOnMeetingServiceRequest({
          variables: {
            input: {
              answers: mapAnswersToRequest(
                options.answers,
                options.meetingServiceQuestions
              ),
              meetingServiceRequestId:
                initialValues.formAction.serviceRequestId,
              previousCompletedFormId: options.previousCompletedFormId,
            },
          },
        });

        const data = value.data?.changeAnswersOnMeetingServiceRequest;

        if (
          data?.__typename ===
          'ChangeAnswersOnMeetingServiceRequestErrorResponse'
        ) {
          if (data.reason === 'CONFLICT') {
            return {
              type: 'error',
              translatedReason: t(`errors.edit_conflict`),
            };
          }
          return {
            type: 'error',
            translatedReason: t(`errors.default`),
          };
        }

        if (
          data?.__typename ===
          'ChangeAnswersOnMeetingServiceRequestSuccessResponse'
        ) {
          trackEvent(
            AmplitudeEvents.CHANGED_ANSWERS_ON_MEETING_SERVICE_REQUEST,
            {
              application: 'dashboard',
            }
          );
          await flushEvents();
          return {
            type: 'success',
            meetingServiceRequestId: initialValues.formAction?.serviceRequestId,
          };
        }
      }
    } catch (e) {
      return {
        type: 'error',
        translatedReason: t(`errors.default`),
      };
    }
    return {
      type: 'error',
      translatedReason: t(`errors.default`),
    };
  }, [
    t,
    initialValues,
    isDataValid,
    changeAnswersOnMeetingServiceRequest,
    options.answers,
    options.meetingServiceQuestions,
    options.previousCompletedFormId,
    trackEvent,
    flushEvents,
  ]);

  const { parent } =
    useManageChildPenpalMessages<WorkplaceServiceRequestParentAPI>({
      changeAnswersOnMeetingServiceRequest: changeMeetingServiceRequestAnswers,
      // Heartbeat method (see types for full description)
      pingChild: () => null,
    });

  useEffect(() => {
    const debouncedCall = debounce(() => {
      if (parent) {
        parent.onTotalPriceChange(options.totalPrice);
      }
    }, 200);

    if (parent) {
      debouncedCall();
    }

    return () => {
      debouncedCall.cancel();
    };
  }, [options.totalPrice, parent]);

  useEffect(() => {
    let shouldSetValues = true;
    const getValues = async () => {
      const values = await parent?.getInitialValues();
      if (values && shouldSetValues) {
        setInitialValues(values);
      }
    };
    getValues();
    return () => {
      shouldSetValues = false;
    };
  }, [parent]);

  return { initialValues };
};
