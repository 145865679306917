import {
  MeetingServiceByIdType,
  useMeetingServicesTableContext,
} from 'components/ServicesTable/contexts';
import { useMeetingServicePageContext } from 'pages/MeetingServicePage/contexts/MeetingServicePageContext';

export const useEditServiceButton = () => {
  const { openServiceFormWithId } = useMeetingServicePageContext();
  const {
    setMeetingServicesById,
    setShowConfirmDelete,
    deselectAllMeetingServices,
  } = useMeetingServicesTableContext();

  const onEdit = (serviceId: string) => openServiceFormWithId(serviceId);
  const onDelete = (serviceId: string) => {
    // First delect all selected services
    deselectAllMeetingServices();

    // Then set the 'selected' service to only the individual one the user is deleting
    setMeetingServicesById((prev) => {
      const current: MeetingServiceByIdType = structuredClone(prev);
      return new Map(
        current.set(serviceId, {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          ...current.get(serviceId)!,
          selected: true,
        })
      );
    });
    setShowConfirmDelete(true);
  };
  const onDuplicate = () => null;

  return { onEdit, onDelete, onDuplicate };
};
