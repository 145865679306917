import { useFormContext } from 'react-hook-form';
import { MeetingServiceType } from 'components/ServiceForm/ServiceFormContainer';
import { useCallback } from 'react';
import { buildNewQuestion } from 'types';
import { v4 as uuidv4 } from 'uuid';

export type UseManageQuestionsAPI = {
  handleAddQuestion: () => void;
};

// Hook for management of lists of questions
export const useManageQuestions = (): UseManageQuestionsAPI => {
  const { setValue, getValues } = useFormContext<MeetingServiceType>();

  const handleAddQuestion = useCallback(() => {
    const questionUuid = uuidv4();

    setValue(
      'questions',
      new Map([
        ...getValues('questions'),
        [questionUuid, buildNewQuestion(questionUuid)],
      ])
    );
  }, [setValue, getValues]);

  return {
    handleAddQuestion,
  };
};
