import {
  createContext,
  useContext,
  FC,
  ReactNode,
  useCallback,
  useState,
  useMemo,
  Dispatch,
  SetStateAction,
} from 'react';
import { ITEMS_PER_TABLE_PAGE } from 'pages/MeetingServicePage/contexts/ServicesContext';
import { GetMeetingServicesForAdminTableListQuery } from 'generated';
import { TableColumnsType } from '@robinpowered/ui-kit';
import {
  TicketsListTableDataType,
  useManageTicketsListTable,
} from '../hooks/useManageTicketsListTable';
import { useTicketsListPageContext } from 'pages/TicketsListPage/contexts/TicketsListPageContext';

export type MeetingServiceType =
  GetMeetingServicesForAdminTableListQuery['listMeetingServices']['meetingServices'][0];

type TicketsListTableValue = {
  tableData: TicketsListTableDataType[];
  columns: TableColumnsType;
  handlePageNumberClick: (pageNum: number) => void;
  activePage: number;
  loadingTicketsListTable: boolean;
  setSkipItems: Dispatch<SetStateAction<number>>;
};

const TicketsListTableContext = createContext<TicketsListTableValue>({
  tableData: [],
  columns: [],
  handlePageNumberClick: () => null,
  activePage: 0,
  loadingTicketsListTable: false,
  setSkipItems: () => null,
});

type Props = {
  children: ReactNode;
};

export const TicketsListTableContextProvider: FC<Props> = ({ children }) => {
  const { refetchTickets, loading: loadingTicketsListTable } =
    useTicketsListPageContext();
  const { tableData, columns } = useManageTicketsListTable();

  const [skipItems, setSkipItems] = useState(0);
  const activePage = useMemo(
    () => (skipItems ? skipItems / ITEMS_PER_TABLE_PAGE + 1 : 1),
    [skipItems]
  );

  const handlePageNumberClick = useCallback(
    (pageNum: number) => {
      if (pageNum === activePage) {
        return;
      }

      const itemsSkipped = (pageNum - 1) * ITEMS_PER_TABLE_PAGE;

      refetchTickets({
        skip: itemsSkipped ? itemsSkipped : undefined,
      });

      setSkipItems(itemsSkipped);
    },
    [activePage, refetchTickets]
  );

  return (
    <TicketsListTableContext.Provider
      value={{
        tableData,
        columns,
        handlePageNumberClick,
        setSkipItems,
        activePage,
        loadingTicketsListTable,
      }}
    >
      {children}
    </TicketsListTableContext.Provider>
  );
};

export const useTicketsListTableContext = (): TicketsListTableValue => {
  return useContext(TicketsListTableContext);
};
