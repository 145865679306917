import { useCallback, useState } from 'react';
import { NoticeType } from 'antd/es/message/interface';
import {
  ApproveMeetingServiceRequestInput,
  RejectMeetingServiceRequestInput,
  useApproveMeetingServiceRequestMutation,
  useRejectMeetingServiceRequestMutation,
} from 'generated';
import { useTranslation } from 'react-i18next';
import { Sentry } from 'lib/sentry';

type UseApprovalsProps = {
  toastMessage: (type: NoticeType, message: string) => void;
};

export const useApprovals = ({ toastMessage }: UseApprovalsProps) => {
  const { t } = useTranslation('TicketsListPage');

  const [approveMeetingServiceRequest, { loading: approvingTicket, client }] =
    useApproveMeetingServiceRequestMutation();
  const [rejectMeetingServiceRequest, { loading: rejectingTicket }] =
    useRejectMeetingServiceRequestMutation();
  const [approvalProcessing, setProcessingApproval] = useState(false);

  const [
    selectedTicketIdForApprovalProcess,
    setSelectedTicketIdForApprovalProcess,
  ] = useState<{
    id: string;
    status: 'approving' | 'rejecting';
  } | null>(null);

  const refetchTickets = useCallback(
    (id: string) => {
      client
        .refetchQueries({
          updateCache(cache) {
            cache.evict({ id: `MeetingServiceRequest:${id}` });
          },
        })
        .finally(() => {
          setProcessingApproval(false);
          setSelectedTicketIdForApprovalProcess(null);
        });
    },
    [client]
  );

  const handleApproveMeetingServiceRequest = useCallback(
    (input: ApproveMeetingServiceRequestInput) => {
      setProcessingApproval(true);
      approveMeetingServiceRequest({
        variables: {
          input,
        },
      })
        .then((res) => {
          if (
            res.data?.approveMeetingServiceRequest.__typename ===
            'ApproveMeetingServiceRequestSuccessResponse'
          ) {
            toastMessage('success', t('approvals.approve_success'));
          } else if (
            res.data?.approveMeetingServiceRequest.__typename ===
            'ApproveMeetingServiceRequestErrorResponse'
          ) {
            if (res.data?.approveMeetingServiceRequest.reason === 'CONFLICT') {
              toastMessage('error', t('approvals.CONFLICT'));
            } else {
              toastMessage('error', t('approvals.default'));
            }
          }
          refetchTickets(input.meetingServiceRequestId);
        })
        .catch((error) => {
          toastMessage('error', t('approvals.default'));
          Sentry.captureException(error);
          refetchTickets(input.meetingServiceRequestId);
        });
    },
    [
      approveMeetingServiceRequest,
      t,
      toastMessage,
      refetchTickets,
      setProcessingApproval,
    ]
  );

  const handleRejectMeetingServiceRequest = useCallback(
    (input: RejectMeetingServiceRequestInput) => {
      setProcessingApproval(true);
      rejectMeetingServiceRequest({
        variables: {
          input,
        },
      })
        .then((res) => {
          if (
            res.data?.rejectMeetingServiceRequest.__typename ===
            'RejectMeetingServiceRequestSuccessResponse'
          ) {
            toastMessage('success', t('approvals.reject_success'));
          }
          if (
            res.data?.rejectMeetingServiceRequest.__typename ===
            'RejectMeetingServiceRequestErrorResponse'
          ) {
            if (res.data?.rejectMeetingServiceRequest.reason === 'CONFLICT') {
              toastMessage('error', t('approvals.CONFLICT'));
            } else {
              toastMessage('error', t('approvals.default'));
            }
          }
          refetchTickets(input.meetingServiceRequestId);
        })
        .catch((error) => {
          toastMessage('error', t('approvals.default'));
          Sentry.captureException(error);
          refetchTickets(input.meetingServiceRequestId);
        });
    },
    [
      rejectMeetingServiceRequest,
      t,
      toastMessage,
      refetchTickets,
      setProcessingApproval,
    ]
  );

  return {
    handleApproveMeetingServiceRequest,
    handleRejectMeetingServiceRequest,
    selectedTicketIdForApprovalProcess,
    setSelectedTicketIdForApprovalProcess,
    /* @TODO probably don't need all these checks, approvalProcessing might be enough */
    approvalProcessing:
      approvingTicket || rejectingTicket || approvalProcessing,
  };
};
