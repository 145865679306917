import { useMemo } from 'react';

export type AssignedUser = {
  id: string;
  userName?: string;
  avatar?: string | null | undefined;
  primaryEmail?: { email?: string };
};
type AssignedGroup = {
  id: string;
  groupName: string;
  members: {
    id: string;
    name?: string;
    avatar?: string | null | undefined;
    primaryEmail?: { email?: string };
  }[];
};

export type Group = {
  __typename?: 'Group';
  id: string;
  groupName: string;
  members: {
    __typename?: 'User';
    id: string;
    avatar?: string | null;
    name?: string | null;
    primaryEmail?: {
      email: string;
    } | null;
  }[];
};

export type User = {
  __typename?: 'User';
  id: string;
  avatar?: string | null;
  name?: string | null;
  primaryEmail?: {
    __typename?: 'Email';
    email: string;
  } | null;
};

export type IAssignees =
  | {
      __typename?: 'UsersAndGroups';
      usersAndGroups: Array<Group | User | null>;
    }
  | null
  | undefined;

/* @TODO move this to a more common location/approvers hook */
export type IApprovers =
  | {
      __typename?: 'UserOrGroup';
      userOrGroup?: Group | User | null;
    }
  | null
  | undefined;

export const useAssignees = (assignees: IAssignees) => {
  const parsedAssignees = useMemo(() => {
    if (!assignees || assignees?.usersAndGroups.length === 0) {
      return undefined;
    }

    // Just finding the first group
    const groups = (assignees?.usersAndGroups.filter(
      (a) => a?.__typename === 'Group'
    ) || []) as AssignedGroup[];
    const users = (assignees?.usersAndGroups.filter(
      (a) => a?.__typename === 'User'
    ) || []) as AssignedUser[];
    return {
      users,
      groups,
    };
  }, [assignees]);

  const avatarStack = useMemo(() => {
    const userAvatars =
      parsedAssignees?.users.map((user) => {
        return {
          name: user.userName ? user.userName : user.primaryEmail?.email || '',
          id: user.id,
          size: 24,
          src: user.avatar || '',
        };
      }) || [];

    const groupAvatars =
      parsedAssignees?.groups.map((group) => {
        return {
          name: group.groupName,
          id: group.id,
          size: 24,
          src: group.groupName || '',
        };
      }) || [];

    return [...groupAvatars, ...userAvatars];
  }, [parsedAssignees?.groups, parsedAssignees?.users]);

  return {
    avatarStack,
    // Parsed meaning we organized them into front end friendly objects
    parsedAssignees,
  };
};
