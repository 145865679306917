import { Typography } from '@robinpowered/ui-kit';
import styled from '@emotion/styled';

export const Header = ({ title }: { title: string }) => {
  return (
    <PageHeader>
      <Typography.Title style={{ fontSize: '22px' }}>{title}</Typography.Title>
    </PageHeader>
  );
};

const PageHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
