import styled from '@emotion/styled';
import { Colors, CoreFontWeight } from '@robinpowered/design-system';
import { Drawer, Grid, Layout, Menu } from '@robinpowered/ui-kit';
import { ChevronRightOutline } from '@robinpowered/icons';
import { useManageSidebar } from './hooks/useManageSidebar';
import { TicketsRoutes } from 'App';
const { Sider } = Layout;

export const NavSidebar = () => {
  const { navItems, selectedKeys, isOpen, setIsOpen } = useManageSidebar();
  const { useBreakpoint } = Grid;
  const { lg } = useBreakpoint();

  return (
    <>
      {!lg && (
        <Drawer
          open={isOpen}
          maskClosable={true}
          placement="left"
          onClose={() => setIsOpen(false)}
        >
          <SideNavigationContainer>
            <Menu
              items={navItems}
              mode="inline"
              selectedKeys={selectedKeys}
              style={{ border: 'none' }}
              defaultOpenKeys={[TicketsRoutes.SETUP]}
            />
          </SideNavigationContainer>
        </Drawer>
      )}
      <SideNavigationWrapper
        style={{ cursor: !isOpen ? 'pointer' : 'default' }}
        width={isOpen && (lg || typeof lg === 'undefined') ? 200 : 20}
      >
        {/* If md is undefined, this prevents the sider from flickering on clicking navitems */}
        {isOpen && (lg || typeof lg === 'undefined') && (
          <SideNavigationContainer>
            <Menu
              items={navItems}
              mode="inline"
              selectedKeys={selectedKeys}
              style={{ border: 'none' }}
              defaultOpenKeys={[TicketsRoutes.SETUP]}
            />
          </SideNavigationContainer>
        )}
        <SidebarToggle
          onClick={() => setIsOpen(!isOpen)}
          className="sidebarToggle"
          open={isOpen}
          onKeyPress={(e) => {
            if (e.key === 'Enter') setIsOpen(!isOpen);
          }}
          tabIndex={0}
        >
          <ChevronRightOutline
            size={12}
            style={{
              transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)',
              transition: 'transform 0.25s ease',
            }}
          />
        </SidebarToggle>
      </SideNavigationWrapper>
    </>
  );
};

const SidebarToggle = styled.div<{ open: boolean }>(
  (props) => `
    align-items: center;
    background-color: ${Colors.White0};
    cursor: pointer;
    display: flex;
    justify-content: center;
    padding: 6px;
    border-radius: 100%;

    position: absolute;
    right: -10px;
    top: 10px;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    visibility: ${props.open ? 'hidden' : 'visible'};
`
);

const SideNavigationContainer = styled.div`
  margin: 25px auto;
`;

const SideNavigationWrapper = styled(Sider)`
  transition: all 0.25s ease;
  position: relative;
  padding: 12px;

  background-color: ${Colors.White0};
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);

  &:hover {
    .sidebarToggle {
      visibility: visible;
    }
  }

  a {
    align-items: center;
    color: ${Colors.Gray80};
    display: flex;
    text-decoration: none;
    font-size: 14px;
    line-height: 22px;
    padding-bottom: 10px;

    &:hover {
      color: ${Colors.Maroon100};
    }

    &.active {
      border-bottom-color: ${Colors.Maroon100};
      color: ${Colors.Maroon100};
      font-weight: ${CoreFontWeight.Medium};

      > .new-tag {
        background-color: ${Colors.Maroon100};
      }
    }
  }
`;
