import styled from '@emotion/styled';
import { Typography } from '@robinpowered/ui-kit';
import { MultiAnswer } from './QuestionTypes/components/MultiAnswer';
import { useTranslation } from 'react-i18next';

type AnsweredExpectingChoices = {
  __typename?: 'AnsweredTicketQuestionExpectingChoices';
  choices: Array<{
    __typename?: 'TicketQuestionOption';
    id: string;
    name: string;
    imageUrl?: string | null;
  }>;
  question: {
    __typename?: 'TicketQuestionExpectingChoices';
    chooseOnlyOneOption: boolean;
    id: string;
    prompt: string;
    required: boolean;
  };
};

type AnsweredExpectingMenuChoices = {
  __typename?: 'AnsweredTicketQuestionExpectingMenuChoices';
  question: {
    __typename?: 'TicketQuestionExpectingMenuChoices';
    chooseOnlyOneOption: boolean;
    id: string;
    prompt: string;
    required: boolean;
  };
  choices: Array<{
    __typename?: 'TicketAnswerMenuChoice';
    quantity?: number | null;
    option: {
      __typename?: 'TicketQuestionMenuOption';
      id: string;
      name: string;
      imageUrl?: string | null;
    };
    subtotalPrice?: {
      __typename?: 'Money';
      amountMajor: string;
      currencyCode: string;
    } | null;
  }>;
};

type AnsweredExpectingText = {
  __typename?: 'AnsweredTicketQuestionExpectingText';
  answer: string;
  question: {
    __typename?: 'TicketQuestionExpectingText';
    multilineAnswer: boolean;
    id: string;
    prompt: string;
    required: boolean;
  };
};

type UnansweredTicketQuestion = {
  __typename?: 'UnansweredTicketQuestion';
  question:
    | {
        __typename?: 'TicketQuestionExpectingChoices';
        id: string;
        prompt: string;
      }
    | {
        __typename?: 'TicketQuestionExpectingMenuChoices';
        id: string;
        prompt: string;
      }
    | {
        __typename?: 'TicketQuestionExpectingText';
        id: string;
        prompt: string;
      };
};

type AbstractAnsweredQuestion =
  | AnsweredExpectingChoices
  | AnsweredExpectingMenuChoices
  | AnsweredExpectingText
  | UnansweredTicketQuestion;

type CompletedForm =
  | {
      __typename?: 'CompletedTicketForm';
      id: string;
      questions: Array<AbstractAnsweredQuestion>;
    }
  | null
  | undefined;

type Props = {
  completedForm: CompletedForm;
};

export const AnsweredQuestions = ({ completedForm }: Props) => {
  const { t } = useTranslation('ServiceDetailsSidebar');
  if (!completedForm) {
    return null;
  }

  /* Render the appropriate question based on the question type */
  const renderQuestion = (abstractQuestion: AbstractAnsweredQuestion) => {
    switch (abstractQuestion.__typename) {
      case 'AnsweredTicketQuestionExpectingChoices': {
        const { question, choices } = abstractQuestion;

        return (
          <QuestionWrapper key={question.id}>
            <Typography.Text strong>{question.prompt}</Typography.Text>
            {choices.map((choice) => (
              <MultiAnswer
                key={choice.id}
                imageUrl={choice.imageUrl}
                name={choice.name}
              />
            ))}
          </QuestionWrapper>
        );
      }
      case 'AnsweredTicketQuestionExpectingMenuChoices': {
        const { question, choices } = abstractQuestion;

        return (
          <QuestionWrapper key={question.id}>
            <Typography.Text strong>{question.prompt}</Typography.Text>
            {choices.map((choice) => (
              <MultiAnswer
                key={choice.option.id}
                imageUrl={choice.option.imageUrl}
                name={choice.option.name}
                quantity={choice.quantity}
                unitPrice={choice.subtotalPrice}
              />
            ))}
          </QuestionWrapper>
        );
      }
      case 'AnsweredTicketQuestionExpectingText': {
        const { answer, question } = abstractQuestion;
        return (
          <QuestionWrapper key={question.id}>
            <Typography.Text strong>{question.prompt}</Typography.Text>
            <Typography.Text>
              {answer ? answer : 'No answer given'}
            </Typography.Text>
          </QuestionWrapper>
        );
      }
      case 'UnansweredTicketQuestion': {
        const { question } = abstractQuestion;

        return (
          <QuestionWrapper key={question.id}>
            <QuestionHeader>
              <Typography.Text strong>{question.prompt}</Typography.Text>
              <Typography.Text type="secondary">
                {t('questions.optional')}
              </Typography.Text>
            </QuestionHeader>
            <Typography.Text type="secondary">
              {t('questions.unanswered')}
            </Typography.Text>
          </QuestionWrapper>
        );
      }
      default:
        return null;
    }
  };

  return (
    <>{completedForm.questions.map((question) => renderQuestion(question))}</>
  );
};

const QuestionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const QuestionHeader = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
`;
