/* eslint-disable @shopify/jsx-no-hardcoded-content */
import { Body } from '@robinpowered/design-system';
import styled from '@emotion/styled';
import { GroupAvatar } from 'components/common/GroupAvatar';

type Props = {
  group:
    | {
        __typename?: 'Group';
        id: string;
        name?: string;
        members: Array<{
          __typename?: 'User';
          id: string;
        }>;
      }
    | null
    | undefined;
  totalUsers: number;
};

export const GroupCell = ({ group, totalUsers }: Props) => {
  if (!group) {
    return null;
  }

  return (
    <UserContainer title={group.name}>
      <GroupAvatar size={'small'} />
      <Body.Small>
        {group.name} ({totalUsers})
      </Body.Small>
    </UserContainer>
  );
};

const UserContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
