import React, { createContext, useContext } from 'react';
import { useCurrencySelect } from '../components/hooks/useCurrencySelect';

type QuestionSectionType = {
  currencyCode: string;
  currencySymbol: string;
  commonCurrencies: {
    label: string;
    value: string;
  }[];
  supportedCurrency: {
    label: string;
    value: string;
  }[];
  handleSelectCurrency: (value: string) => void;
};

const QuestionSectionContext = createContext<QuestionSectionType>({
  currencyCode: 'USD',
  currencySymbol: '$',
  commonCurrencies: [],
  supportedCurrency: [],
  handleSelectCurrency: () => null,
});

export const QuestionSectionProvider = ({
  children,
}: {
  children: JSX.Element;
}): JSX.Element => {
  const {
    currencyCode,
    currencySymbol,
    commonCurrencies,
    supportedCurrency,
    handleSelectCurrency,
  } = useCurrencySelect();

  return (
    <QuestionSectionContext.Provider
      value={{
        currencyCode,
        currencySymbol,
        commonCurrencies,
        supportedCurrency,
        handleSelectCurrency,
      }}
    >
      {children}
    </QuestionSectionContext.Provider>
  );
};

export const useQuestionSectionContext = (): QuestionSectionType => {
  return useContext(QuestionSectionContext);
};
