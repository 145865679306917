import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { useMeetingServicesLocationContext } from 'pages/MeetingServicePage/contexts/LocationContext';
import { Select, type SelectProps } from '@robinpowered/ui-kit';
import { useMemo } from 'react';

const { Option } = Select;

export const SelectLocations = () => {
  const { t } = useTranslation('MeetingServicePage');

  const {
    selectedLocations,
    handleDeselectAll,
    handleSelectBuilding,
    handleSelect,
    locationOptions,
    loading,
  } = useMeetingServicesLocationContext();

  const options: SelectProps['options'] = locationOptions;
  const value = useMemo(
    () =>
      !loading && selectedLocations.length && locationOptions.length
        ? selectedLocations
        : [],
    [locationOptions.length, selectedLocations, loading]
  );

  return (
    <SelectLocationInputWrapper>
      <Select
        showSearch
        allowClear
        maxTagCount={1}
        maxTagTextLength={20}
        // TODO: this mode causes a react mem leak error. Tried setting it via use effect
        // with a cleanup, need to keep trying things.  Internal ant design problem. Same problem
        // when trying it with their default 'multi' select example code in our app
        mode={'multiple'}
        placeholder={t(`selectLocation.placeholder`)}
        onChange={handleSelectBuilding}
        value={value}
        style={{ width: '100%', maxHeight: '100px' }}
        optionFilterProp="label"
        loading={loading}
        onSelect={handleSelect}
        onClear={handleDeselectAll}
      >
        <Option key={t('selectLocation.select_all')}>
          {t(`selectLocation.select_all`)}
        </Option>
        {options.map((option, i) => {
          return (
            <Option
              key={`${option.value}${i}`}
              value={option.value}
              label={option.label}
            >
              {option.label}
            </Option>
          );
        })}
      </Select>
    </SelectLocationInputWrapper>
  );
};

const SelectLocationInputWrapper = styled.div`
  width: 100%;
  max-width: 275px;
`;
