import { createContext, useContext, FC, ReactNode } from 'react';
import {
  GetLocationNameDetailsForServiceRequestDetailsSidebarQuery,
  GetMeetingServicesForAdminTableListQuery,
  GetServiceRequestByIdForDetailSidebarQuery,
  MeetingServiceRequestStatus,
  useGetLocationNameDetailsForServiceRequestDetailsSidebarQuery,
  useGetServiceRequestByIdForDetailSidebarQuery,
} from 'generated';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useApolloContext, useAuthContext } from 'contexts';
import { message } from '@robinpowered/ui-kit';
import { useTranslation } from 'react-i18next';
import { TicketsRoutes } from 'App';

export type MeetingServiceType =
  GetMeetingServicesForAdminTableListQuery['listMeetingServices']['meetingServices'][0];

type ServiceDetailsSidebarValue = {
  serviceDetails:
    | GetServiceRequestByIdForDetailSidebarQuery['getMeetingServiceRequestByIdentifier']['meetingServiceRequest']
    | undefined;
  loading: boolean;
  locationData:
    | GetLocationNameDetailsForServiceRequestDetailsSidebarQuery
    | undefined;
  serviceRequestKey: string | null;
  setSearchParams: (params: URLSearchParams) => void;
  serviceDetailsSidebarMessageContextHolder: ReactNode | null;
  userCanApprove: boolean;
};

const ServiceDetailsSidebarContext = createContext<ServiceDetailsSidebarValue>({
  serviceDetails: undefined,
  loading: false,
  locationData: undefined,
  serviceRequestKey: null,
  setSearchParams: () => null,
  serviceDetailsSidebarMessageContextHolder: null,
  userCanApprove: false,
});

export const ServiceDetailsSidebarContextProvider: FC<{
  children: ReactNode;
}> = ({ children }) => {
  const { tenantId } = useApolloContext();
  const { currentOrg } = useAuthContext();
  const { t } = useTranslation('ServiceDetailsSidebar');
  const [
    serviceDetailsSidebarMessageApi,
    serviceDetailsSidebarMessageContextHolder,
  ] = message.useMessage();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const serviceRequestKey = searchParams.get('serviceRequestKey');
  const navigateWithRemovedParam = (paramName: string) => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.delete(paramName);
    // Using the replace prevents the users from hitting a looo
    // using the browser back btn to hit a page that keeps erroring
    navigate(
      `/${currentOrg?.slug}/tickets/${
        TicketsRoutes.TICKETS_LIST
      }?${newSearchParams.toString()}`,
      {
        replace: true,
      }
    );
  };

  const { data, loading } = useGetServiceRequestByIdForDetailSidebarQuery({
    variables: {
      identifier: {
        key: serviceRequestKey,
      },
    },
    skip: !serviceRequestKey || !tenantId,
    onError: () => {
      serviceDetailsSidebarMessageApi.error(
        t('errors.loading_service_details')
      );
      // If there is an error, clear the search params
      navigateWithRemovedParam('serviceRequestKey');
    },
    onCompleted: (data) => {
      // If the service details are not found, clear the search params
      if (!data.getMeetingServiceRequestByIdentifier.meetingServiceRequest) {
        serviceDetailsSidebarMessageApi.error(t('errors.not_found'));
        navigateWithRemovedParam('serviceRequestKey');
      }
    },
  });

  const serviceDetails =
    data?.getMeetingServiceRequestByIdentifier.meetingServiceRequest;

  const { data: locationData, loading: locationLoading } =
    useGetLocationNameDetailsForServiceRequestDetailsSidebarQuery({
      variables: {
        locationId:
          serviceDetails?.regardsEventAtSpace.space?.locationId.toString() ||
          '',
        levelId: serviceDetails?.regardsEventAtSpace.space?.levelId || '',
        hasLevelId: !!serviceDetails?.regardsEventAtSpace.space?.levelId,
      },
      skip: !serviceDetails?.regardsEventAtSpace.space?.locationId,
    });

  const userCanApprove =
    !!serviceDetails?.permissions?.canApproveOrReject &&
    serviceDetails?.meetingServiceRequestStatus ===
      MeetingServiceRequestStatus.NeedsApproval;

  return (
    <ServiceDetailsSidebarContext.Provider
      value={{
        serviceRequestKey,
        setSearchParams,
        serviceDetails,
        locationData,
        loading: loading || locationLoading,
        serviceDetailsSidebarMessageContextHolder,
        userCanApprove,
      }}
    >
      {children}
    </ServiceDetailsSidebarContext.Provider>
  );
};

export const useServiceDetailsSidebarContext =
  (): ServiceDetailsSidebarValue => {
    return useContext(ServiceDetailsSidebarContext);
  };
