export const getInitials = function (string: string) {
  if (!string) {
    return '';
  }

  const names = string.split(' ');
  let initials = names[0].substring(0, 1).toUpperCase();

  if (names.length > 1) {
    initials += names[names.length - 1].substring(0, 1).toUpperCase();
  }
  return initials;
};

type TotalPrice = {
  amountMajor: string;
  currencyCode: string;
};

export const formatCurrency = (totalPrice: TotalPrice) => {
  return new Intl.NumberFormat(navigator.language, {
    style: 'currency',
    currencyDisplay: 'narrowSymbol',
    currency: totalPrice?.currencyCode,
    // can take a string, types are off: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/format
  }).format(totalPrice?.amountMajor as unknown as number);
};
