import {
  AnsweredTicketQuestionInput,
  TicketQuestionExpectingMenuChoices,
} from 'generated';
import { TicketQuestionMenuOption } from 'generated';
import {
  Answers,
  GetMeetingServiceByIdForServiceRequestQuestions,
  selectedServiceWithQuestions,
} from '../types/ServiceRequestTypes';

export const getTotalPrice = (
  serviceRequestAnswers: Answers,
  meetingService: selectedServiceWithQuestions
) => {
  let total = 0;

  serviceRequestAnswers.forEach((answer, key) => {
    const associatedQuestion = meetingService?.questions.find(
      (q) =>
        q.id === key && q.__typename === 'TicketQuestionExpectingMenuChoices'
    ) as TicketQuestionExpectingMenuChoices | undefined;
    if (associatedQuestion && answer.type === 'choices') {
      answer.options.forEach((option, optionId) => {
        const associatedOption = associatedQuestion.options.find(
          (o) => o.id === optionId
        ) as TicketQuestionMenuOption;

        if (associatedOption && associatedOption.unitPrice) {
          total +=
            (option.quantity ?? 1) *
            Number(associatedOption.unitPrice.amountMajor);
        }
      });
    }
  });

  if (
    !total ||
    !meetingService?.currencyCode ||
    serviceRequestAnswers.size === 0
  ) {
    return undefined;
  }

  return new Intl.NumberFormat(navigator.language, {
    style: 'currency',
    currencyDisplay: 'narrowSymbol',
    currency: meetingService.currencyCode,
  }).format(total);
};

export const mapAnswersToRequest = (
  answers: Answers,
  meetingServiceQuestions:
    | GetMeetingServiceByIdForServiceRequestQuestions
    | undefined
): Array<AnsweredTicketQuestionInput> => {
  const mappedAnswers = [...answers.entries()].map(([questionId, answer]) => {
    if (answer.type === 'unanswered') {
      return {
        unanswered: {
          questionId,
        },
      };
    }
    if (answer.type === 'text') {
      return {
        text: {
          questionId,
          answer: answer.text,
        },
      };
    }

    // Check the original question type to see if the answer should be of
    // type TicketAnswerExpectingMenuChoicesInput
    const associatedQuestion =
      meetingServiceQuestions &&
      meetingServiceQuestions.find(
        (q) =>
          q.__typename === 'TicketQuestionExpectingMenuChoices' &&
          q.id === questionId
      );

    if (associatedQuestion) {
      return {
        menuChoices: {
          questionId,
          choices: [...answer.options.entries()].map(
            ([optionId, { quantity }]) => {
              return {
                optionId,
                quantity,
              };
            }
          ),
        },
      };
    }

    // Otherwise they are TicketAnswerExpectingChoicesInput
    return {
      choices: {
        questionId,
        optionIds: [...answer.options.keys()],
      },
    };
  });

  return mappedAnswers;
};
