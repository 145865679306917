import { useAuthContext } from 'contexts';
import {
  ApproveMeetingServiceRequestInput,
  GetTicketsForTicketsListPageQuery,
  ListMeetingServiceRequestsSortByInput,
  RejectMeetingServiceRequestInput,
  useGetMeetingServiceRequestsCountForTicketsListTablePaginationQuery,
  useGetTicketsForTicketsListPageQuery,
} from 'generated';
import {
  createContext,
  useContext,
  FC,
  ReactNode,
  useMemo,
  SetStateAction,
  Dispatch,
  useState,
  JSXElementConstructor,
  ReactElement,
  useCallback,
} from 'react';
import { useTicketsListLocationContext } from './LocationContext';
import { useTenantLocalStorage } from 'hooks/useTenantLocalStorage';
import { message } from '@robinpowered/ui-kit';
import { NoticeType } from 'antd/es/message/interface';
import { useApprovals } from '../hooks/useApprovals';

type RefetchFilters = {
  skip?: number | undefined;
  after?: string | undefined;
};
type TicketsListPageValue = {
  data: GetTicketsForTicketsListPageQuery | undefined;
  ticketById: Map<
    string,
    GetTicketsForTicketsListPageQuery['listMeetingServiceRequests']['meetingServiceRequests'][0]
  >;
  refetchTickets: (filters?: RefetchFilters) => void;
  loading: boolean;
  meetingServiceRequestsCount: number;
  refetchMeetingServiceRequestsCount: () => void;
  sortByForListServiceRequestsQuery:
    | ListMeetingServiceRequestsSortByInput
    | null
    | undefined;
  setSortByForListServiceRequestsQuery: Dispatch<
    SetStateAction<ListMeetingServiceRequestsSortByInput | null | undefined>
  >;
  toastContextHolder: ReactElement<
    unknown,
    string | JSXElementConstructor<unknown>
  >;
  toastMessage: (type: NoticeType, message: string) => void;
  handleApproveMeetingServiceRequest: (
    input: ApproveMeetingServiceRequestInput
  ) => void;
  handleRejectMeetingServiceRequest: (
    input: RejectMeetingServiceRequestInput
  ) => void;
  selectedTicketIdForApprovalProcess: {
    id: string;
    status: 'approving' | 'rejecting';
  } | null;
  setSelectedTicketIdForApprovalProcess: Dispatch<
    SetStateAction<{
      id: string;
      status: 'approving' | 'rejecting';
    } | null>
  >;
  approvalProcessing: boolean;
};

const TicketsListPageContext = createContext<TicketsListPageValue>({
  data: undefined,
  ticketById: new Map(),
  refetchTickets: () => null,
  loading: true,
  meetingServiceRequestsCount: 0,
  refetchMeetingServiceRequestsCount: () => null,
  setSortByForListServiceRequestsQuery: () => null,
  sortByForListServiceRequestsQuery: null,
  toastContextHolder: <></>,
  toastMessage: () => null,
  handleApproveMeetingServiceRequest: () => null,
  handleRejectMeetingServiceRequest: () => null,
  /* @TODO this should be selectedTickets? In the future we will have multiple tickets selected that can be deleted */
  selectedTicketIdForApprovalProcess: null,
  setSelectedTicketIdForApprovalProcess: () => null,
  approvalProcessing: false,
});

type Props = {
  children: ReactNode;
};

export const TICKETS_PER_TABLE_PAGE = 15;

export const TicketsListPageContextProvider: FC<Props> = ({ children }) => {
  const { loading: loadingAuth } = useAuthContext();

  // Kind of hacky, but handles the loading state not properly updating when refetching
  // only use it in this file
  const [isRefetching, setIsRefetching] = useState(false);

  const [messageApi, contextHolder] = message.useMessage();
  const toastMessage = useCallback(
    (type: NoticeType, message: string) => {
      messageApi.open({
        type,
        content: message,
      });
    },
    [messageApi]
  );

  const {
    selectedLocations,
    locationsUserCanManage,
    loading: loadingLocations,
  } = useTicketsListLocationContext();

  const [
    sortByForListServiceRequestsQuery,
    setSortByForListServiceRequestsQuery,
  ] = useTenantLocalStorage<ListMeetingServiceRequestsSortByInput | null>(
    'list-meeting-service-requests-table-sort',
    null
  );

  const { data, loading, refetch } = useGetTicketsForTicketsListPageQuery({
    variables: {
      input: {
        first: TICKETS_PER_TABLE_PAGE,
        sortBy: sortByForListServiceRequestsQuery || undefined,
        skip: undefined,
        after: undefined,
      },
    },
    skip: loadingAuth,
  });

  const ticketById = useMemo(() => {
    const ticketData = data?.listMeetingServiceRequests.meetingServiceRequests;
    return new Map(ticketData?.map((ticket) => [ticket.id, ticket]));
  }, [data]);

  const availableInAnyBuildingIdsOrTheirDescendants = useMemo(() => {
    return selectedLocations.length
      ? selectedLocations.filter((location) => !!location)
      : locationsUserCanManage.map((location) => {
          return location?.id || '';
        });
  }, [selectedLocations, locationsUserCanManage]);

  const {
    data: dataForCountServiceRequests,
    refetch: refetchMeetingServiceRequestsCount,
  } = useGetMeetingServiceRequestsCountForTicketsListTablePaginationQuery({
    variables: {
      input: {
        filters: {
          availableInAnyBuildingIdsOrTheirDescendants,
        },
      },
    },
    skip:
      loadingLocations ||
      availableInAnyBuildingIdsOrTheirDescendants.length < 1,
  });

  const {
    handleApproveMeetingServiceRequest,
    handleRejectMeetingServiceRequest,
    selectedTicketIdForApprovalProcess,
    setSelectedTicketIdForApprovalProcess,
    approvalProcessing,
  } = useApprovals({
    toastMessage,
  });

  return (
    <TicketsListPageContext.Provider
      value={{
        meetingServiceRequestsCount:
          dataForCountServiceRequests?.countMeetingServiceRequests.count || 0,
        refetchMeetingServiceRequestsCount: () => {
          // TODO once filters are ready, make this have access to them
          refetchMeetingServiceRequestsCount();
        },
        data,
        ticketById,
        loading: loading || isRefetching,
        setSortByForListServiceRequestsQuery,
        sortByForListServiceRequestsQuery,
        refetchTickets: (filters?: RefetchFilters) => {
          setIsRefetching(true);
          // TODO once filters are ready, make this have access to them
          refetch({
            input: {
              first: TICKETS_PER_TABLE_PAGE,
              skip: filters?.skip,
              after: filters?.after,
            },
          }).finally(() => {
            setIsRefetching(false);
          });
        },
        toastContextHolder: contextHolder,
        toastMessage,
        handleApproveMeetingServiceRequest,
        handleRejectMeetingServiceRequest,
        selectedTicketIdForApprovalProcess,
        setSelectedTicketIdForApprovalProcess,
        approvalProcessing,
      }}
    >
      {children}
    </TicketsListPageContext.Provider>
  );
};

export const useTicketsListPageContext = (): TicketsListPageValue => {
  return useContext(TicketsListPageContext);
};
