import styled from '@emotion/styled';
import { Colors } from '@robinpowered/design-system';
import { RobinLoader } from 'components/RobinLoader';
import { Alert } from '@robinpowered/ui-kit';
import { useTranslation } from 'react-i18next';
import { ServiceDetailCard } from 'components/common/ServiceDetailCard/ServiceDetailCard';
import { MarkdownRenderer } from 'components/common/MarkdownRenderer/MarkdownRenderer';
import {
  ChangeServiceRequestAnswersContextProvider,
  useChangeServiceRequestAnswersContext,
} from './contexts/ChangeServiceRequestAnswersContext';
import { ServiceQuestions } from '../components/ServiceQuestions';

const ChangeServiceRequestAnswersContainer = () => {
  const {
    loading,
    isRecurring,
    requestedBy,
    dueDate,
    locationName,
    numOfAttendees,
    serviceDescription,
    assignees,
    serviceName,
    categoryName,
    questions,
    serviceRequestAnswers,
    setServiceRequestAnswers,
    setAnswersHaveError,
    answersHaveError,
  } = useChangeServiceRequestAnswersContext();
  const { t } = useTranslation('MeetingServiceRequest');

  if (loading) {
    return <RobinLoader />;
  }

  return (
    <Wrapper>
      {isRecurring && (
        <Alert
          message={t(`recurrence_alert.add_service`)}
          type="info"
          showIcon
        />
      )}
      <ServiceDetailCard
        dueDate={dueDate}
        loading={loading}
        requestedBy={requestedBy}
        hideEventTitle
        locationName={locationName}
        numOfAttendees={numOfAttendees}
        serviceName={serviceName}
        categoryName={categoryName}
        assignees={assignees}
      />

      {serviceDescription && (
        <MarkdownRenderer markdownString={serviceDescription} />
      )}
      {questions && (
        <ServiceQuestions
          serviceQuestions={questions}
          answers={serviceRequestAnswers}
          setAnswers={setServiceRequestAnswers}
          setErrors={setAnswersHaveError}
          errors={answersHaveError}
        />
      )}
    </Wrapper>
  );
};

export const ChangeServiceRequestAnswersForm = () => {
  return (
    <ChangeServiceRequestAnswersContextProvider>
      <ChangeServiceRequestAnswersContainer />
    </ChangeServiceRequestAnswersContextProvider>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 100%;
  background-color: ${Colors.White0};
  padding: 16px;
  overflow-y: auto;
`;
