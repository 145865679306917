import { Grid, Pagination as RobinPagination } from '@robinpowered/ui-kit';
import { useMeetingServicesTableContext } from 'components/ServicesTable/contexts';
import {
  ITEMS_PER_TABLE_PAGE,
  useMeetingServicesContext,
} from 'pages/MeetingServicePage/contexts/ServicesContext';
import { useTranslation } from 'react-i18next';

export const Pagination = (): JSX.Element | null => {
  const { t } = useTranslation('MeetingServicePage');
  const { meetingServicesCount } = useMeetingServicesContext();
  const { handlePageNumberClick, activePage } =
    useMeetingServicesTableContext();
  const { useBreakpoint } = Grid;
  const { lg } = useBreakpoint();

  return (
    <RobinPagination
      current={activePage}
      simple={!lg}
      total={meetingServicesCount}
      showTotal={(total) => t('table.pagination.total', { count: total })}
      pageSize={ITEMS_PER_TABLE_PAGE}
      onChange={handlePageNumberClick}
      hideOnSinglePage={meetingServicesCount <= ITEMS_PER_TABLE_PAGE}
      showSizeChanger={false}
    />
  );
};
