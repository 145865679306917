import { Body, Colors, Flex } from '@robinpowered/design-system';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import moment from 'moment';
import { Button, TableColumnsType } from '@robinpowered/ui-kit';
import { useTicketsListPageContext } from 'pages/TicketsListPage/contexts/TicketsListPageContext';
import { UsersCell } from '../../common/TableCells/Users';
import {
  ListMeetingServiceRequestsSortByCriteria,
  MeetingServiceRequestStatus,
  SortOrder,
} from 'generated';
import { Assignee } from 'components/common/TableCells/Assignee';
import { useAuthContext } from 'contexts';
import { TicketsRoutes } from 'App';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { ServiceRequestStatus } from 'components/common/TableCells/ServiceStatus';
import Checkmark from '@robinpowered/ui-kit-icons/Checkmark';
import { Approver } from 'components/common/TableCells/Approver';
import { Close } from '@robinpowered/icons';

export type TicketsListTableDataType = {
  status: JSX.Element;
  serviceKey: JSX.Element;
  startAt: string;
  serviceName: JSX.Element;
  space: string;
  key: string;
  requester: JSX.Element;
  category: string;
  createdAt: string;
  updatedAt: string;
};

export const useManageTicketsListTable = () => {
  const { t } = useTranslation('TicketsListPage');
  const { currentOrg } = useAuthContext();
  const {
    ticketById,
    sortByForListServiceRequestsQuery,
    handleApproveMeetingServiceRequest,
    setSelectedTicketIdForApprovalProcess,
    selectedTicketIdForApprovalProcess,
    approvalProcessing,
  } = useTicketsListPageContext();
  const navigate = useNavigate();

  const tableActions = useCallback(
    (id: string) => {
      const meetingServiceRequest = ticketById.get(id);

      const userCanApprove =
        !!meetingServiceRequest?.permissions?.canApproveOrReject &&
        meetingServiceRequest?.meetingServiceRequestStatus ===
          MeetingServiceRequestStatus.NeedsApproval;

      const isProcessingApproval =
        approvalProcessing &&
        selectedTicketIdForApprovalProcess?.id === meetingServiceRequest?.id;

      return (
        <Flex style={{ gap: 16, display: 'flex', justifyContent: 'center' }}>
          {userCanApprove ? (
            <>
              <Button
                size="small"
                type="primary"
                icon={<Checkmark />}
                loading={isProcessingApproval}
                onClick={() => {
                  setSelectedTicketIdForApprovalProcess({
                    id: meetingServiceRequest.id,
                    status: 'approving',
                  });
                  handleApproveMeetingServiceRequest({
                    latestChangeId: meetingServiceRequest.latestChangeId,
                    meetingServiceRequestId: meetingServiceRequest.id,
                  });
                }}
              />
              <Button
                size="small"
                type="default"
                style={{
                  borderColor: Colors.Red100,
                }}
                loading={isProcessingApproval}
                icon={<Close color={Colors.Red100} size={14} />}
                onClick={() =>
                  setSelectedTicketIdForApprovalProcess({
                    id: meetingServiceRequest.id,
                    status: 'rejecting',
                  })
                }
              />
            </>
          ) : (
            <>
              {/* <Button icon={<EditAlt size={16} />} disabled></Button>
              <Button icon={<TrashSolid size={16} />} disabled></Button> */}
            </>
          )}
        </Flex>
      );
    },
    [
      ticketById,
      handleApproveMeetingServiceRequest,
      setSelectedTicketIdForApprovalProcess,
      approvalProcessing,
      selectedTicketIdForApprovalProcess,
    ]
  );

  // From AntDesign table docs: please try to leave one column at least without width to fit fluid layout
  const columns: TableColumnsType = [
    {
      title: t('table.columns.status'),
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: t('table.columns.service_key'),
      dataIndex: 'serviceKey',
      key: 'serviceKey',
    },
    {
      title: t('table.columns.start_at'),
      dataIndex: 'startAt',
      key: 'startAt',
      width: 175,
    },
    {
      title: t('table.columns.service_name'),
      dataIndex: 'serviceName',
      key: 'serviceName',
    },
    {
      title: t('table.columns.space'),
      dataIndex: 'space',
      key: 'space',
    },
    {
      title: t('table.columns.event_title'),
      dataIndex: 'eventTitle',
      key: 'eventTitle',
    },
    {
      title: t('table.columns.approver'),
      dataIndex: 'approver',
      key: 'approver',
      width: 175,
    },
    {
      title: t('table.columns.requester'),
      dataIndex: 'requester',
      key: 'requester',
    },
    {
      title: t('table.columns.assignee'),
      dataIndex: 'assignee',
      key: 'assignee',
    },
    {
      title: t('table.columns.category'),
      dataIndex: 'category',
      key: 'category',
      width: 175,
    },
    {
      title: t('table.columns.created_at'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: true,
      sortOrder:
        sortByForListServiceRequestsQuery?.criteria ===
        ListMeetingServiceRequestsSortByCriteria.CreatedAt
          ? sortByForListServiceRequestsQuery?.order === SortOrder.Ascending
            ? 'ascend'
            : 'descend'
          : undefined,
    },
    {
      title: t('table.columns.updated_at'),
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      sorter: true,
      sortOrder:
        sortByForListServiceRequestsQuery?.criteria ===
        ListMeetingServiceRequestsSortByCriteria.UpdatedAt
          ? sortByForListServiceRequestsQuery?.order === SortOrder.Ascending
            ? 'ascend'
            : 'descend'
          : undefined,
      width: 175,
    },
    {
      title: 'Action',
      dataIndex: 'operation',
      key: 'operation',
      fixed: 'right',
      width: 100,
      render: (_text, record) => {
        return tableActions(record.id);
      },
    },
  ];

  const tableData = useMemo(() => {
    return (
      Array.from(ticketById.values())?.map((serviceRequest) => {
        return {
          key: serviceRequest.key,
          status: (
            <ServiceRequestStatus
              status={serviceRequest.meetingServiceRequestStatus}
              meetingServiceRequestId={serviceRequest.id}
            />
          ),
          serviceKey: (
            <Button
              type="link"
              aria-label="Open the ticket details in sidebar"
              onClick={() => {
                navigate({
                  pathname: `/${currentOrg?.slug}/tickets/${TicketsRoutes.TICKETS_LIST}`,
                  search: createSearchParams({
                    serviceRequestKey: serviceRequest.key,
                  }).toString(),
                });
              }}
            >
              <TruncatedName title={serviceRequest.key}>
                {serviceRequest.key}
              </TruncatedName>
            </Button>
          ),
          startAt: moment(serviceRequest.regardsEventAtSpace.eventStart).format(
            'MMM D h:mm a'
          ),
          serviceName: (
            <TruncatedName title={serviceRequest.meetingService.name}>
              {serviceRequest.meetingService.name}
            </TruncatedName>
          ),
          space: serviceRequest.regardsEventAtSpace.space?.name || '',
          eventTitle:
            serviceRequest.regardsEventAtSpace.event?.title ||
            t('table.rows.event.no_title'),
          approver: <Approver approver={serviceRequest.approvers} />,
          requester: <UsersCell users={[serviceRequest.requester]} />,
          category: serviceRequest.meetingService.category.name,
          assignee: <Assignee assignees={serviceRequest.assignees} />,
          createdAt: moment(serviceRequest.createdAt).format('MMM D h:mm a'),
          updatedAt: moment(serviceRequest.updatedAt).format('MMM D h:mm a'),
          id: serviceRequest.id /* @TODO Hidden column. Kind of a hack but can't see any issues rn */,
        };
      }) || []
    );
  }, [ticketById, t, navigate, currentOrg?.slug]);

  return {
    tableData,
    columns,
  };
};

const TruncatedName = styled(Body.Small)`
  max-width: 350px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
`;
