import styled from '@emotion/styled';
import { Body } from '@robinpowered/design-system';
import { InputNumber, Select } from '@robinpowered/ui-kit';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { useQuestionSectionContext } from '../../../contexts/QuestionSectionContext';

export const UnitPrice = ({
  onUpdateUnitPrice,
  unitPrice,
}: {
  onUpdateUnitPrice: (unitPrice: string, currencyCode: string) => void;
  unitPrice: string;
}): JSX.Element => {
  const { t } = useTranslation('QuestionsSection');
  const {
    currencySymbol,
    currencyCode,
    commonCurrencies,
    supportedCurrency,
    handleSelectCurrency,
  } = useQuestionSectionContext();

  const selectAfter = useMemo(() => {
    return (
      <Select
        defaultValue={currencyCode}
        showSearch={true}
        style={{ width: 90 }}
        value={currencyCode}
        options={[
          {
            label: t('menu.currency_segments.common'),
            title: t('menu.currency_segments.common'),
            options: commonCurrencies,
          },
          {
            label: t('menu.currency_segments.supported'),
            title: t('menu.currency_segments.supported'),
            options: supportedCurrency,
          },
        ]}
        onSelect={handleSelectCurrency}
      />
    );
  }, [
    currencyCode,
    commonCurrencies,
    supportedCurrency,
    handleSelectCurrency,
    t,
  ]);

  return (
    <Wrapper>
      <Body.Small style={{ minWidth: '120px' }}>
        {t('menu_options.unit_price')}
      </Body.Small>
      <InputNumber<string>
        /* Temp bandage */
        style={{ width: 'auto', position: 'relative', left: '-2px' }}
        addonAfter={selectAfter}
        prefix={currencySymbol}
        value={unitPrice}
        onChange={(e) => onUpdateUnitPrice(e || '0', currencyCode)}
        min={'0'}
        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
        stringMode
        precision={3}
        max={'99999999'}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
