import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragEndEvent,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import {
  restrictToVerticalAxis,
  restrictToParentElement,
} from '@dnd-kit/modifiers';
import { useCallback, useEffect, useMemo } from 'react';
import { QuestionBuilder } from '../QuestionBuilder/QuestionBuilder';
import styled from '@emotion/styled';
import { useFormContext } from 'react-hook-form';
import { MeetingServiceType } from 'components/ServiceForm/ServiceFormContainer';
import { QuestionSectionProvider } from '../contexts/QuestionSectionContext';

export const QuestionBuilderList = (): JSX.Element => {
  const { watch, setValue, clearErrors } = useFormContext<MeetingServiceType>();
  const questions = watch('questions');

  // Used for the dnd kit sorting api
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragEnd = useCallback(
    (e: DragEndEvent) => {
      const { active, over } = e;
      if (active.id && over !== null && active.id !== over.id) {
        const oldIndex = Array.from(questions.values())
          .map((question) => question.id)
          .indexOf(active.id as string);
        const newIndex = Array.from(questions.values())
          .map((question) => question.id)
          .indexOf(over.id as string);

        const orderedQuestions = arrayMove(
          Array.from(questions.values()),
          oldIndex,
          newIndex
        );
        setValue('questions', new Map(orderedQuestions.map((q) => [q.id, q])));
      }
    },
    [setValue, questions]
  );

  const questionsWithoutContent = useMemo(() => {
    return new Set(
      Array.from(questions.values()).filter((question) => {
        return question.hasEmptyContent ? question.id : null;
      })
    );
  }, [questions]);

  useEffect(() => {
    if (questionsWithoutContent.size === 0) {
      clearErrors('questions');
    }
    // Clear question errors if question.hasEmptyContent is false
  }, [questionsWithoutContent, clearErrors]);

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
      modifiers={[restrictToVerticalAxis, restrictToParentElement]}
    >
      <SortableContext
        items={Array.from(questions.values())}
        strategy={verticalListSortingStrategy}
      >
        <QuestionSectionProvider>
          <Wrapper>
            {Array.from(questions.values()).map((question, i) => {
              return <QuestionBuilder key={question.id} question={question} />;
            })}
          </Wrapper>
        </QuestionSectionProvider>
      </SortableContext>
    </DndContext>
  );
};

const Wrapper = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
