import { MeetingServiceType } from 'components';
import {
  commonCurrenciesByLocale,
  getCurrencySymbol,
} from 'components/ServiceForm/utils';
import { useCallback, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { UITicketQuestion, UITicketQuestionExpectingMenuChoices } from 'types';

export const useCurrencySelect = () => {
  const { setValue, watch, getValues } = useFormContext<MeetingServiceType>();
  const currencyCode = watch('currencyCode');

  const currencySymbol = useMemo(
    () => getCurrencySymbol(navigator.language, currencyCode),
    [currencyCode]
  );

  const handleSelectCurrency = useCallback(
    (value: string) => {
      const questions = getValues('questions');
      const updatedQuestions = structuredClone(questions);
      updatedQuestions.forEach((question: UITicketQuestion) => {
        if (question.type === 'menu') {
          (question as UITicketQuestionExpectingMenuChoices).options.forEach(
            (option) => {
              if (option.unitPrice) {
                option.unitPrice.currencyCode = value;
              }
            }
          );
        }
      });
      setValue('questions', updatedQuestions);
      setValue('currencyCode', value);
    },
    [setValue, getValues]
  );

  const commonCurrencies = useMemo(
    () =>
      [...new Set(commonCurrenciesByLocale.values())].map((currencyCode) => ({
        label: currencyCode,
        value: currencyCode,
      })),
    []
  );

  const supportedCurrency = useMemo(() => {
    return Intl.supportedValuesOf('currency')
      .filter((currency: string) => {
        return ![...commonCurrenciesByLocale.entries()].some(([, value]) => {
          return value === currency;
        });
      })
      .map((currency: string) => ({ label: currency, value: currency }));
  }, []);

  return {
    commonCurrencies,
    supportedCurrency,
    currencyCode,
    currencySymbol,
    handleSelectCurrency,
  };
};
