import React from 'react';
import { GroupCell } from './Groups';
import { UsersCell } from './Users';
import { GetTicketsForTicketsListPageQuery } from 'generated';
import { Flex } from '@robinpowered/ui-kit';
import { useTranslation } from 'react-i18next';

type ApproverType =
  GetTicketsForTicketsListPageQuery['listMeetingServiceRequests']['meetingServiceRequests'][0]['approvers'];

type Props = {
  approver: ApproverType | undefined;
};

export const Approver = ({ approver }: Props) => {
  const { t } = useTranslation('common');
  if (approver?.userOrGroup?.__typename === 'Group') {
    return (
      <GroupCell
        group={approver.userOrGroup}
        totalUsers={approver.userOrGroup.members.length}
      />
    );
  }

  if (approver?.userOrGroup?.__typename === 'User') {
    const user = {
      ...approver.userOrGroup,
      name: approver.userOrGroup.userName,
    };
    return <UsersCell users={[user]} />;
  }

  return (
    <Flex
      style={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      {t('detail_card.empty_cell')}
    </Flex>
  );
};
