import { gql, QueryResult, useQuery } from '@apollo/client';
import { useAuthContext } from '../contexts';
import {
  FeatureFlagType,
  FeatureFlagQueryQuery,
  FeatureFlagQueryQueryVariables,
} from '../generated';

export const FEATURE_FLAG_QUERY = gql`
  query FeatureFlagQuery($flagType: FeatureFlagType) {
    isFeatureFlagEnabledForOrgOrMe(flagType: $flagType)
  }
`;

export const useFeatureFlag = (
  flagType: FeatureFlagType
): QueryResult<FeatureFlagQueryQuery, FeatureFlagQueryQueryVariables> => {
  const { currentOrg } = useAuthContext();

  return useQuery<FeatureFlagQueryQuery>(FEATURE_FLAG_QUERY, {
    variables: { flagType },
    nextFetchPolicy: 'cache-only',
    skip: !currentOrg?.id,
  });
};
