import styled from '@emotion/styled';
import { Colors } from '@robinpowered/design-system';
import { MeetingServices } from 'components/MeetingServices/MeetingServices';
import { ServiceFormContainer } from 'components/ServiceForm';
import {
  MeetingServicesContextProvider,
  useMeetingServicesContext,
} from 'pages/MeetingServicePage/contexts/ServicesContext';
import { ToastProvider, useToast } from 'contexts';
import { MeetingServicesLocationProvider } from 'pages/MeetingServicePage/contexts/LocationContext';
import { useTranslation } from 'react-i18next';
import {
  MeetingServicePageContextProvider,
  useMeetingServicePageContext,
} from './contexts/MeetingServicePageContext';
import { NavSidebar } from 'components/NavSidebar/NavSidebar';
import { Layout } from '@robinpowered/ui-kit';

const MeetingServicesContainer = () => {
  const { t } = useTranslation('MeetingServicePage');
  const { refetchMeetingServices, refetchMeetingServicesCount } =
    useMeetingServicesContext();
  const toast = useToast();
  const { serviceFormOpen, setServiceFormOpen } =
    useMeetingServicePageContext();

  return (
    <>
      <ScrollWrapper>
        <MeetingServiceWrapper>
          <MeetingServices />
        </MeetingServiceWrapper>
      </ScrollWrapper>
      {serviceFormOpen && (
        <ServiceFormContainer
          onSuccessfulSubmit={(status: 'added' | 'updated') => {
            refetchMeetingServicesCount();
            setServiceFormOpen(false);
            toast.success(t(`toasts.service_${status}`));
            refetchMeetingServices();
          }}
        />
      )}
    </>
  );
};

export const MeetingServicePage = () => {
  return (
    <Layout>
      <NavSidebar />
      <ToastProvider>
        <MeetingServicesLocationProvider>
          <MeetingServicesContextProvider>
            <MeetingServicePageContextProvider>
              <MeetingServicesContainer />
            </MeetingServicePageContextProvider>
          </MeetingServicesContextProvider>
        </MeetingServicesLocationProvider>
      </ToastProvider>
    </Layout>
  );
};

const ScrollWrapper = styled.div`
  height: calc(100vh - 56px);
  background-color: ${Colors.Gray5};
  width: 100%;
  overflow-y: auto;
  padding: 32px;
`;

const MeetingServiceWrapper = styled.div`
  background-color: ${Colors.White0};
  width: 100%;
  height: 100%;
  border-radius: 8px;
  border: 1px solid ${Colors.Gray10};
  padding: var(--Space-Margin-marginMD, 20px);
`;
