import common from './common.json';
import error from './error.json';
import QuestionsSection from './QuestionsSection.json';
import ServiceSection from './ServiceSection.json';
import ServiceForm from './ServiceForm.json';
import MeetingServicePage from './MeetingServicePage.json';
import MeetingServiceRequest from './MeetingServiceRequest.json';
import TicketsListPage from './TicketsListPage.json';
import Sidebar from './Sidebar.json';
import ServiceDetailsSidebar from './ServiceDetailsSidebar.json';

export const en = {
  common,
  error,
  QuestionsSection,
  ServiceSection,
  ServiceForm,
  MeetingServicePage,
  MeetingServiceRequest,
  TicketsListPage,
  Sidebar,
  ServiceDetailsSidebar,
};
