import styled from '@emotion/styled';
import { Body, Colors } from '@robinpowered/design-system';
import { formatCurrency } from 'lib/utility';
import { useTranslation } from 'react-i18next';

type Props = {
  imageUrl?: string | null | undefined;
  name: string;
  description?: string;
  quantity?: number | null | undefined;
  unitPrice?:
    | {
        __typename?: 'Money';
        amountMajor: string;
        currencyCode: string;
      }
    | null
    | undefined;
};

export const MultiAnswer = ({
  imageUrl,
  name,
  description,
  quantity,
  unitPrice,
}: Props) => {
  const { t } = useTranslation('common');
  return (
    <>
      <OptionContainer>
        <OptionInfoWrapper>
          {imageUrl && (
            <ImageContainer>
              <img
                src={imageUrl}
                loading="lazy"
                alt={name}
                style={{ maxWidth: '100%', maxHeight: '100%' }}
              />
            </ImageContainer>
          )}

          <OptionInfo>
            <Body.Small>{name}</Body.Small>
            {description && (
              <Body.Small style={{ color: Colors.Gray60 }}>
                {description}
              </Body.Small>
            )}
          </OptionInfo>
        </OptionInfoWrapper>
        {quantity && (
          <QuantityContainer>
            <Body.Small>{t('multi_answer.quantity')}</Body.Small>
            <Body.Small>{quantity}</Body.Small>
          </QuantityContainer>
        )}

        {unitPrice && (
          <>
            <Body.Small color={Colors.Gray60}>
              {formatCurrency(unitPrice)}
            </Body.Small>
          </>
        )}
      </OptionContainer>
    </>
  );
};

const OptionContainer = styled.div`
  display: flex;
  flex-direction: row;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colorTokens.stroke.layer2};
  gap: 16px;
  align-items: center;
  padding: 0.75rem 1rem;
`;

const OptionInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  width: 100%;
`;

const OptionInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

const ImageContainer = styled.div`
  display: flex;
  gap: 10px;
  border-radius: 4px;
  width: fit-content;
  align-items: center;
  justify-content: center;
  width: 88px;
  height: 88px;
  position: relative;
`;

const QuantityContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
