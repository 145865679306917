import { useFormContext } from 'react-hook-form';
import { MeetingServiceType } from 'components/ServiceForm/ServiceFormContainer';
import { useCallback, useMemo } from 'react';
import {
  UITicketQuestion,
  UITicketQuestionExpectingChoices,
  UITicketQuestionExpectingMenuChoices,
  buildEmptyChoiceOption,
  buildEmptyMenuOption,
  isMultiChoiceQuestionType,
  updateQuestionType,
} from 'types';
import { v4 as uuidv4 } from 'uuid';
import { QuestionDropdownTypes } from './QuestionBuilder';
import Graphemer from 'graphemer';

const MAX_PROMPT_LENGTH = 200;

export type UseManageQuestionAPI = {
  handleSelectDropdown: (
    type: QuestionDropdownTypes,
    question: UITicketQuestion
  ) => void;
  handleUpdatePrompt: (text: string, question: UITicketQuestion) => void;
  handleAddChoiceOption: (question: UITicketQuestionExpectingChoices) => void;
  handleAddMenuOption: (question: UITicketQuestionExpectingMenuChoices) => void;
  handleToggleRequired: (question: UITicketQuestion) => void;
  handleToggleMultiSelect: (question: UITicketQuestionExpectingChoices) => void;
  handleDeleteQuestion: (questionId: string) => void;
  firstQuestionWithEmptyPromptById: string | undefined;
};

// Hook for management of individual 'questions'
export const useManageQuestion = (): UseManageQuestionAPI => {
  const { clearErrors, watch, setValue, getValues } =
    useFormContext<MeetingServiceType>();
  const questions = watch('questions');

  const firstQuestionWithEmptyPromptById = useMemo(() => {
    return Array.from(questions.values()).find((q) => q.hasEmptyContent)?.id;
  }, [questions]);

  const handleUpdatePrompt = useCallback(
    (text: string, question: UITicketQuestion) => {
      if (new Graphemer().countGraphemes(text) > MAX_PROMPT_LENGTH) {
        return;
      }
      const hasEmptyContent =
        !text.trim().length ||
        (isMultiChoiceQuestionType(question) &&
          question.options.some((o) => !o.name.trim().length));

      const updatedQuestion = {
        ...question,
        prompt: text,
        hasEmptyContent,
      };

      const updatedQuestionMap = structuredClone(getValues('questions'));
      updatedQuestionMap.set(question.id, updatedQuestion);
      setValue('questions', updatedQuestionMap);
    },
    [getValues, setValue]
  );

  const handleSelectDropdown = useCallback(
    (type: QuestionDropdownTypes, question: UITicketQuestion) => {
      const updatedQuestion = updateQuestionType(
        question,
        type,
        getValues('currencyCode')
      );

      const updatedQuestionMap = structuredClone(getValues('questions'));
      updatedQuestionMap.set(question.id, updatedQuestion);
      setValue('questions', updatedQuestionMap);
    },
    [getValues, setValue]
  );

  const handleAddMenuOption = useCallback(
    (question: UITicketQuestionExpectingMenuChoices) => {
      const updatedQuestion = {
        ...question,
        hasEmptyContent: true,
        options: [
          ...question.options,
          buildEmptyMenuOption(uuidv4(), getValues('currencyCode')),
        ],
      };

      const updatedQuestionMap = structuredClone(getValues('questions'));
      updatedQuestionMap.set(question.id, updatedQuestion);

      setValue('questions', updatedQuestionMap);
    },
    [setValue, getValues]
  );

  const handleAddChoiceOption = useCallback(
    (question: UITicketQuestionExpectingChoices) => {
      const updatedQuestion = {
        ...question,
        hasEmptyContent: true,
        options: [...question.options, buildEmptyChoiceOption(uuidv4())],
      };

      const updatedQuestionMap = structuredClone(getValues('questions'));
      updatedQuestionMap.set(question.id, updatedQuestion);

      setValue('questions', updatedQuestionMap);
    },
    [setValue, getValues]
  );

  const handleToggleRequired = useCallback(
    (question: UITicketQuestion) => {
      const updatedQuestion = {
        ...question,
        required: !question.required,
      };

      const updatedQuestionMap = structuredClone(getValues('questions'));
      updatedQuestionMap.set(question.id, updatedQuestion);

      setValue('questions', updatedQuestionMap);
    },
    [getValues, setValue]
  );

  const handleToggleMultiSelect = useCallback(
    (question: UITicketQuestionExpectingChoices) => {
      const updatedQuestion = {
        ...question,
        chooseOnlyOneOption: !question.chooseOnlyOneOption,
      };

      const updatedQuestionMap = structuredClone(getValues('questions'));
      updatedQuestionMap.set(question.id, updatedQuestion);

      setValue('questions', updatedQuestionMap);
    },
    [getValues, setValue]
  );

  const handleDeleteQuestion = useCallback(
    (questionId: string) => {
      const prevQuestions = structuredClone(getValues('questions'));
      prevQuestions.delete(questionId);
      clearErrors('questions');
      setValue('questions', prevQuestions);
    },
    [getValues, clearErrors, setValue]
  );
  return {
    handleUpdatePrompt,
    handleSelectDropdown,
    handleAddChoiceOption,
    handleAddMenuOption,
    handleToggleRequired,
    handleToggleMultiSelect,
    handleDeleteQuestion,
    firstQuestionWithEmptyPromptById,
  };
};
