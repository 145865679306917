import { useQuestionContext } from 'components/ServiceRequest/components/QuestionTypes/contexts/QuestionContext';

import Graphemer from 'graphemer';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export const useTextAnswer = (type: 'short' | 'long') => {
  const { t } = useTranslation('MeetingServiceRequest');
  const MAX_ANSWER_LENGTH = type === 'short' ? 200 : 2000;
  const {
    id,
    required,
    hasError,
    setHasError,
    answer: questionAnswer,
    setAnswers,
  } = useQuestionContext();

  const answer = useMemo(() => {
    if (questionAnswer?.type === 'choices') {
      return undefined;
    }

    return questionAnswer;
  }, [questionAnswer]);

  const error = useMemo(
    () => (hasError && required ? t('errors.required') : undefined),
    [hasError, required, t]
  );

  const handleChange = useCallback(
    (value: string, required: boolean) => {
      setHasError(false);
      if (new Graphemer().countGraphemes(value) > MAX_ANSWER_LENGTH) return;
      setAnswers((prev) => {
        const prevCopy = structuredClone(prev);

        if (value) {
          prevCopy.set(id, {
            type: 'text',
            text: value,
          });
        } else {
          if (required) {
            prevCopy.delete(id);
          } else {
            prevCopy.set(id, { type: 'unanswered' });
          }
        }

        return prevCopy;
      });
    },
    [MAX_ANSWER_LENGTH, id, setAnswers, setHasError]
  );

  return {
    answer,
    error,
    handleChange,
  };
};
