import { AvailableInSpacesByBuilding, MeetingServiceType } from 'components';
import { QuestionDropdownTypes } from 'components/ServiceForm/sections/QuestionsSection/QuestionBuilder';
import {
  FacilitatorGroup,
  FacilitatorUser,
} from 'components/ServiceForm/sections/ServiceSection/sections/usePersonGroupSearch';
import { getDefaultCurrencyCode } from 'components/ServiceForm/utils';
import {
  GetMeetingServiceByIdForEditScreenQuery,
  TicketQuestionExpectingChoices,
  TicketQuestionExpectingMenuChoices,
  TicketQuestionExpectingText,
  TicketQuestionInput,
  TicketQuestionMenuOption,
  TicketQuestionOption,
} from 'generated';
import { v4 as uuidv4 } from 'uuid';

// We add this type to tickets in the frontend to help manage questions
type UITicketQuestionType = {
  /** Unique uuids allow use to do drag/drop and ordering */
  id: string;
  /**
   * Dropdown types help us persist the 'question type' as the user
   * navigates between the questions and settings forms
   */
  type: QuestionDropdownTypes;
  hasEmptyContent: boolean;
};

export type UITicketQuestionOption = TicketQuestionOption & {
  id: string;
};
export type UITicketQuestionMenuOption = TicketQuestionMenuOption & {
  id: string;
};

/** Options of multi answer type guards */
export type UITicketQuestionOptionInput =
  | UITicketQuestionOption
  | UITicketQuestionMenuOption;

/** Can be used when we need to show 'quantifiable' ui */
export const areOptionsMenuType = (
  options: UITicketQuestionOptionInput[]
): options is UITicketQuestionMenuOption[] => {
  return (
    (options as UITicketQuestionMenuOption[])[0].quantifiable !== undefined
  );
};

export const isOptionMenuType = (
  option: UITicketQuestionOptionInput
): option is UITicketQuestionMenuOption => {
  return (option as UITicketQuestionMenuOption).quantifiable !== undefined;
};

// NEED TO UPDATE THESE
export type UITicketQuestionExpectingChoices = Omit<
  TicketQuestionExpectingChoices,
  'options'
> &
  UITicketQuestionType & {
    options: UITicketQuestionOption[];
  };

export type UITicketQuestionExpectingMenuChoices = Omit<
  TicketQuestionExpectingMenuChoices,
  'options'
> &
  UITicketQuestionType & {
    options: UITicketQuestionMenuOption[];
  };

type UITicketQuestionExpectingText = TicketQuestionExpectingText &
  UITicketQuestionType;

export type UITicketQuestion =
  | UITicketQuestionExpectingChoices
  | UITicketQuestionExpectingMenuChoices
  | UITicketQuestionExpectingText;

type UIMultiChoiceTicketQuestion =
  | UITicketQuestionExpectingChoices
  | UITicketQuestionExpectingMenuChoices;

/* question has to exist for this to return true */
export const isMultiChoiceQuestionType = (
  question: UITicketQuestion
): question is UIMultiChoiceTicketQuestion => {
  return (question as UIMultiChoiceTicketQuestion).options !== undefined;
};

export const isMenuQuestionType = (
  question: UITicketQuestion
): question is UITicketQuestionExpectingMenuChoices => {
  return (
    isMultiChoiceQuestionType(question) &&
    (question as UITicketQuestionExpectingMenuChoices).options[0]
      .quantifiable !== undefined
  );
};

export const buildEmptyMenuOption = (
  id: string,
  currencyCode: string
): UITicketQuestionMenuOption => {
  return {
    id,
    name: '',
    description: null,
    imageUrl: null,
    quantifiable: true,
    unitPrice: {
      amountMajor: '0',
      currencyCode,
    },
  };
};

export const buildEmptyChoiceOption = (id: string): UITicketQuestionOption => {
  return {
    id,
    name: '',
    description: null,
    imageUrl: null,
  };
};

export const buildNewQuestion = (id: string): UITicketQuestion => {
  return {
    id,
    multilineAnswer: false,
    prompt: '',
    required: false,
    type: 'short',
    hasEmptyContent: true,
  };
};

export const updateQuestionType = (
  question: UITicketQuestion,
  /**
   * Type is appended only in the UI when the 'question type' dropdown
   * is changed.
   */
  type: QuestionDropdownTypes,
  currencyCode: string
): UITicketQuestion => {
  const { prompt, required, id } = question;

  const mapExistingOptions = (
    question: UIMultiChoiceTicketQuestion,
    currentType: QuestionDropdownTypes
  ) => {
    const mappedOptions = question.options.map((option) => {
      return currentType === 'menu'
        ? {
            id: option.id,
            description: option.description,
            imageUrl: option.imageUrl,
            name: option.name,
            unitPrice: {
              amountMajor: '0',
              currencyCode,
            },
            quantifiable:
              (option as UITicketQuestionMenuOption)?.quantifiable ?? true,
          }
        : {
            id: option.id,
            description: option.description,
            imageUrl: option.imageUrl,
            name: option.name,
          };
    });

    /* Multi chose questions need to have at least 2 options */
    if (!question.chooseOnlyOneOption && question.options.length < 2) {
      return [
        ...mappedOptions,
        buildEmptyChoiceOption(uuidv4()),
      ] as UITicketQuestionOption[];
    }
    return mappedOptions;
  };

  switch (type) {
    case 'long':
      return {
        id,
        prompt,
        required,
        multilineAnswer: true,
        type,
        hasEmptyContent: prompt.length === 0,
      };
    case 'multiple_choice':
      return {
        id,
        prompt,
        required,
        chooseOnlyOneOption:
          (question as UITicketQuestionExpectingChoices).chooseOnlyOneOption ||
          false,
        type,
        hasEmptyContent: true,
        options: isMultiChoiceQuestionType(question)
          ? (mapExistingOptions(
              question,
              'multiple_choice'
            ) as UITicketQuestionOption[])
          : /* Defaulted to Multi chose so 2 options are needed */
            ([
              buildEmptyChoiceOption(uuidv4()),
              buildEmptyChoiceOption(uuidv4()),
            ] as UITicketQuestionOption[]),
      };
    case 'menu':
      return {
        id,
        prompt,
        required,
        chooseOnlyOneOption: false,
        type,
        hasEmptyContent: true,
        options: isMultiChoiceQuestionType(question)
          ? (mapExistingOptions(
              question,
              'menu'
            ) as UITicketQuestionMenuOption[])
          : /* Defaulted to Multi chose so 2 options are needed */ ([
              buildEmptyMenuOption(uuidv4(), currencyCode),
            ] as UITicketQuestionMenuOption[]),
      };

    default:
      return {
        id,
        prompt,
        required,
        multilineAnswer: false,
        type,
        hasEmptyContent: prompt.length === 0,
      };
  }
};

export const buildUIQuestionFromQueryType = (
  question:
    | TicketQuestionExpectingChoices
    | TicketQuestionExpectingText
    | TicketQuestionExpectingMenuChoices
): UITicketQuestion => {
  switch (question.__typename) {
    case 'TicketQuestionExpectingChoices':
      return {
        ...question,
        type: 'multiple_choice',
        hasEmptyContent: false,
      };
    case 'TicketQuestionExpectingText':
      return {
        ...question,
        type: question.multilineAnswer ? 'long' : 'short',
        hasEmptyContent: false,
      };
    case 'TicketQuestionExpectingMenuChoices':
      return {
        ...question,
        type: 'menu',
        hasEmptyContent: false,
      };
    default:
      return {
        ...question,
        type: 'short',
        hasEmptyContent: false,
      };
  }
};

/*
  Essentially the opposite of `updateQuestionType`
  Take the current shape of each question object and
  convert it into the appropriate shape for the `createService` mutation
*/
export const convertUITicketQuestionToAddMeetingServiceQuestionInput = (
  questions: UITicketQuestion[]
): TicketQuestionInput[] => {
  if (!questions) return [];

  return questions.map((question) => {
    // We append `type` and `id` properties to the questions in the frontend
    // for manipulation in the dom. Here we rm them to pass back to backend
    // same with Options on a question, we give them ids which we must rm
    if (isMultiChoiceQuestionType(question)) {
      const {
        type,
        id,
        hasEmptyContent,
        __typename,
        ...questionWithoutTypeAndId
      } = question;

      return isMenuQuestionType(question)
        ? {
            expectingMenuChoices: {
              ...questionWithoutTypeAndId,
              options: question.options.map((option) => {
                const { id, unitPrice, __typename, ...optionWithoutId } =
                  option;

                return unitPrice
                  ? {
                      ...optionWithoutId,
                      unitPrice: {
                        amountMajor: unitPrice.amountMajor,
                        currencyCode: unitPrice.currencyCode,
                      },
                    }
                  : optionWithoutId;
              }),
            },
          }
        : {
            expectingChoices: {
              ...questionWithoutTypeAndId,
              options: question.options.map((option) => {
                const { id, __typename, ...optionWithoutId } = option;
                return { ...optionWithoutId };
              }),
            },
          };
    }
    const {
      type,
      id,
      hasEmptyContent,
      __typename,
      ...questionWithoutTypeAndId
    } = question;
    return { expectingText: { ...questionWithoutTypeAndId } };
  });
};

/**
 *
 * When a user is editing or updating an existing service, we need to call this and map
 * the service to the request form
 */
export const mapMeetingServiceQueryToFormValues = (
  data: GetMeetingServiceByIdForEditScreenQuery | undefined
): MeetingServiceType => {
  const meetingService = data?.getMeetingServiceById.meetingService;

  if (meetingService) {
    const groups =
      (meetingService.assignees?.usersAndGroups
        .filter((g) => g?.__typename === 'Group')
        .map((a) => {
          if (a?.__typename === 'Group') {
            return {
              id: a.id,
              name: a.groupName,
              members:
                a.members.map((m) => {
                  return {
                    id: m.id,
                    name: m.name,
                    avatar: m.avatar,
                    primaryEmail: {
                      email: m.primaryEmail?.email || '',
                    },
                  };
                }) || [],
            };
          }
          return a;
        }) as FacilitatorGroup[]) || [];

    const users =
      (meetingService.assignees?.usersAndGroups
        .filter((u) => u?.__typename === 'User')
        .map((a) => {
          if (a?.__typename === 'User') {
            return {
              id: a.id,
              name: a?.userName,
              avatar: a.avatar,
              primaryEmail: {
                email: a.primaryEmail?.email || '',
              },
            };
          }
          return a;
        }) as FacilitatorUser[]) || [];

    // We currently only handle one
    const approverUser =
      meetingService.approvers?.userOrGroup?.__typename === 'User'
        ? [
            {
              id: meetingService.approvers.userOrGroup.id,
              name: meetingService.approvers.userOrGroup?.userName,
              avatar: meetingService.approvers.userOrGroup.avatar,
              primaryEmail: {
                email:
                  meetingService.approvers?.userOrGroup.primaryEmail?.email ||
                  '',
              },
            },
          ]
        : [];

    const approverGroup =
      meetingService.approvers?.userOrGroup?.__typename === 'Group'
        ? [
            {
              id: meetingService.approvers?.userOrGroup?.id,
              name: meetingService.approvers?.userOrGroup.groupName,
              members:
                meetingService.approvers?.userOrGroup.members.map((m) => {
                  return {
                    id: m.id,
                    name: m.name,
                    avatar: m.avatar,
                    primaryEmail: {
                      email: m.primaryEmail?.email || '',
                    },
                  };
                }) || [],
            },
          ]
        : [];

    const questions = new Map(
      meetingService.questions.map((q) => {
        return [q.id, buildUIQuestionFromQueryType(q)];
      })
    );

    const availableInSpacesByBuilding: AvailableInSpacesByBuilding = new Map(
      meetingService.availabilityInBuildings.buildingAvailability.map((a) => {
        return [a.buildingId, undefined];
      })
    );

    return {
      questions,
      category: {
        name: meetingService.category.name,
        key: meetingService.category.key,
        id: meetingService.category.id,
      },
      description: meetingService.description || '',
      serviceName: meetingService.name,
      availableInSpacesByBuilding,
      assignees: {
        users,
        groups,
      },
      approvers: { users: approverUser, groups: approverGroup },
      currencyCode:
        meetingService.currencyCode?.toString() || getDefaultCurrencyCode(),
    };
  }

  return {
    questions: new Map(),
    category: { name: '', key: '', id: '' },
    description: '',
    serviceName: '',
    availableInSpacesByBuilding: new Map(),
    assignees: { users: [], groups: [] },
    approvers: { users: [], groups: [] },
    currencyCode: getDefaultCurrencyCode(),
  };
};
