import { useMemo } from 'react';
import { useGetLocationNameDetailsForChangingMeetingServiceRequestQuery } from 'generated';
import { useAuthContext } from 'contexts';

export const useGetServiceRequestDetails = ({
  spaceName,
  locationId,
  levelId,
}: {
  spaceName: string | undefined;
  locationId: string | undefined;
  levelId: string | undefined;
}) => {
  const { loading: authLoading } = useAuthContext();

  const { data: locationData, loading: loadingLocation } =
    useGetLocationNameDetailsForChangingMeetingServiceRequestQuery({
      variables: {
        locationId: locationId ?? '',
        levelId: levelId ?? '',
        hasLevelId: !!levelId,
      },
      skip: !locationId || !levelId || authLoading,
    });

  const locationName = useMemo(() => {
    return [
      spaceName,
      locationData?.getLevelById?.name,
      locationData?.getLocationById?.name,
    ]
      .filter((x) => x)
      .join(', ');
  }, [locationData, spaceName]);

  return {
    locationName,
    loading: loadingLocation || authLoading,
  };
};
