// These pages are meant to be handled as a microfrontend embedded in an iframe
// on another 'container' application
import { RequestingServiceForm } from '../components/ServiceRequest/RequestingServiceForm/RequestingServiceForm';
import { ChangeServiceRequestAnswersForm } from '../components/ServiceRequest/ChangeServiceRequestAnswersForm/ChangeServiceRequestAnswersForm';

export const RequestingServicePage = () => {
  return <RequestingServiceForm />;
};

export const ChangeServiceRequestAnswersPage = () => {
  return <ChangeServiceRequestAnswersForm />;
};
