import { useCallback, useState } from 'react';
import {
  Answers,
  selectedServiceWithQuestions,
} from '../types/ServiceRequestTypes';

export const useValidateData = (
  meetingService: selectedServiceWithQuestions
) => {
  const [answersHaveError, setAnswersHaveError] = useState(false);

  const isDataValid = useCallback(
    (answers: Answers) => {
      if (
        meetingService?.questions.find((q) => q.required && !answers.has(q.id))
      ) {
        return false;
      }

      const requiredQuestions = meetingService?.questions.filter(
        (q) => q.required
      );

      const hasInvalidQuestion = requiredQuestions?.find((question) => {
        const answerToQuestion = answers.get(question.id);
        if (
          (answerToQuestion?.type === 'choices' &&
            answerToQuestion.options.size === 0) ||
          (answerToQuestion?.type === 'text' && !answerToQuestion.text)
        ) {
          return true;
        }

        return false;
      });

      return !hasInvalidQuestion;
    },
    [meetingService?.questions]
  );

  return {
    isDataValid,
    setAnswersHaveError,
    answersHaveError,
  };
};
