export const getCurrencySymbol = (locale: string, currencyCode: string) => {
  const currency = new Intl.NumberFormat(locale, {
    style: 'currency',
    currencyDisplay: 'narrowSymbol',
    currency: currencyCode,
  })
    .formatToParts(0)
    .find((x) => x.type === 'currency')?.value;

  return currency || 'USD';
};

export const commonLocales = [
  {
    locale: 'en-GB',
    currencyCode: 'GBP',
  },
  {
    locale: 'en-US',
    currencyCode: 'USD',
  },
  {
    locale: 'es-AR',
    currencyCode: 'ARS',
  },
  {
    locale: 'en-AU',
    currencyCode: 'AUD',
  },
  {
    locale: 'fr-FR',
    currencyCode: 'EUR',
  },
  {
    locale: 'es-ES',
    currencyCode: 'EUR',
  },
  {
    locale: 'bg-BG',
    currencyCode: 'BGN',
  },
  {
    locale: 'pt-BR',
    currencyCode: 'BRL',
  },
  {
    locale: 'pt-BR',
    currencyCode: 'BRL',
  },
  {
    locale: 'en-CA',
    currencyCode: 'CAD',
  },
  {
    locale: 'es-CL',
    currencyCode: 'CLP',
  },
  {
    locale: 'zh-CN',
    currencyCode: 'CNY',
  },
  {
    locale: 'es-CO',
    currencyCode: 'COP',
  },
  {
    locale: 'da-DK',
    currencyCode: 'DKK',
  },
  {
    locale: 'it-IT',
    currencyCode: 'EUR',
  },
  {
    locale: 'hi-IN',
    currencyCode: 'INR',
  },
  {
    locale: 'he-IL',
    currencyCode: 'ILS',
  },
  {
    locale: 'ja-JP',
    currencyCode: 'JPY',
  },
  {
    locale: 'ko-KR',
    currencyCode: 'KRW',
  },
  {
    locale: 'es-MX',
    currencyCode: 'MXN',
  },
  {
    locale: 'nl-CW',
    currencyCode: 'ANG',
  },
  {
    locale: 'en-NZ',
    currencyCode: 'NZD',
  },
  {
    locale: 'no-NO',
    currencyCode: 'NOK',
  },
  {
    locale: 'es-PE',
    currencyCode: 'PEN',
  },
  {
    locale: 'en-PH',
    currencyCode: 'PHP',
  },
  {
    locale: 'pl-PL',
    currencyCode: 'PLN',
  },
  {
    locale: 'ro-RO',
    currencyCode: 'RON',
  },
  {
    locale: 'ar-SA',
    currencyCode: 'SAR',
  },
  {
    locale: 'en-SG',
    currencyCode: 'SGD',
  },
  {
    locale: 'en-ZA',
    currencyCode: 'ZAR',
  },
  {
    locale: 'sv-SE',
    currencyCode: 'SEK',
  },
  {
    locale: 'de-CH',
    currencyCode: 'CHF',
  },
  {
    locale: 'de-DE',
    currencyCode: 'EUR',
  },
  {
    locale: 'uk-UA',
    currencyCode: 'UAH',
  },
];

export const commonCurrenciesByLocale = new Map(
  commonLocales.map((cl) => {
    return [cl.locale, cl.currencyCode];
  })
);

export const getDefaultCurrencyCode = () => {
  const usersCurrencyCode = commonCurrenciesByLocale.get(navigator.language);

  return usersCurrencyCode ? usersCurrencyCode : 'USD';
};
