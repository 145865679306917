import styled from '@emotion/styled';
import { Row } from './components/Row';
import { useTranslation } from 'react-i18next';
import { LoadingDetailCard } from './components/LoadingDetailCard';
import { Assignees } from './components/Assignees/Assignees';
import { MeetingServiceRequestStatus } from 'generated';
import { ServiceRequestStatus } from '../TableCells/ServiceStatus';
import { IApprovers, IAssignees } from './components/Assignees/useAssignees';
import { theme, Typography } from '@robinpowered/ui-kit';
import { Assignee } from './components/Assignees/components/Assignee';
import { Approver } from '../TableCells/Approver';

type Props = {
  serviceId?: string;
  status?: MeetingServiceRequestStatus;
  dueDate: string;
  spaceName?: string;
  locationName?: string;
  numOfAttendees?: number;
  eventTitle?: string | null;
  // Allows us to explicitly hide the event title on 'draft' event screens
  // We still want empty values on other screens displayed as '(No title)'
  hideEventTitle?: boolean;
  categoryName?: string | null;
  serviceName?: string | null;
  loading: boolean;
  serviceKey?: string;
  assignees: IAssignees;
  /* @TODO Making this optional for now. Its breaking some types */
  approver?: IApprovers;
  requestedBy?: {
    name?: string | null | undefined;
    avatar?: string | null | undefined;
    primaryEmail?: {
      __typename?: 'Email';
      email: string;
    } | null;
  } | null;
};

export const ServiceDetailCard = ({
  serviceId,
  serviceKey,
  status,
  eventTitle,
  hideEventTitle,
  categoryName,
  requestedBy,
  serviceName,
  dueDate,
  spaceName,
  locationName,
  assignees,
  approver,
  numOfAttendees,
  loading,
}: Props) => {
  const { t } = useTranslation('common');
  const { useToken } = theme;
  const { token } = useToken();

  return (
    <Wrapper
      style={{
        backgroundColor: token.colorFillQuaternary,
        border: `1px solid ${token.colorBorderSecondary}`,
        borderRadius: token.borderRadiusSM,
      }}
    >
      {loading ? (
        [
          t(`detail_card.status`),
          t(`detail_card.due`),
          t(`detail_card.event_title`),
          t(`detail_card.location`),
          t(`detail_card.requested`),
          t(`detail_card.category`),
          t(`detail_card.service_name`),
          t(`detail_card.attendees`),
          t(`detail_card.approvers`),
          t(`detail_card.key`),
        ].map((title, index) => {
          return <LoadingDetailCard title={title} key={index} />;
        })
      ) : (
        <>
          {status && serviceId && (
            <Row title={t(`detail_card.status`)}>
              <ServiceRequestStatus
                meetingServiceRequestId={serviceId}
                status={status}
              />
            </Row>
          )}

          <Row title={t(`detail_card.requested`)}>
            <Assignee
              name={requestedBy?.name || ''}
              avatar={requestedBy?.avatar || ''}
              label={requestedBy?.name || ''}
              email={requestedBy?.primaryEmail?.email}
            />
          </Row>

          <Row title={t(`detail_card.due`)}>
            <Typography.Text>{dueDate}</Typography.Text>
          </Row>

          {(spaceName || locationName) && (
            <Row title={t(`detail_card.location`)}>
              <LocationName>
                <Typography.Text>{spaceName}</Typography.Text>
                <Typography.Text>{locationName}</Typography.Text>
              </LocationName>
            </Row>
          )}

          {!hideEventTitle && (
            <Row title={t(`detail_card.event_title`)}>
              <Typography.Text>
                {eventTitle ? eventTitle : t(`detail_card.no_event_title`)}
              </Typography.Text>
            </Row>
          )}

          {categoryName && (
            <Row title={t(`detail_card.category`)}>
              <Typography.Text>{categoryName}</Typography.Text>
            </Row>
          )}

          {serviceName && (
            <Row title={t(`detail_card.service_name`)}>
              <Typography.Text>{serviceName}</Typography.Text>
            </Row>
          )}

          {numOfAttendees && (
            <Row title={t(`detail_card.attendees`)}>
              <Typography.Text>{numOfAttendees}</Typography.Text>
            </Row>
          )}

          {assignees && (
            <Row title={'Assignees'}>
              <Assignees assignees={assignees} />
            </Row>
          )}

          {approver && (
            <Row title={t(`detail_card.approvers`)}>
              <Approver approver={approver} />
            </Row>
          )}

          {serviceKey && (
            <Row title={t(`detail_card.key`)}>
              <Typography.Text>{serviceKey}</Typography.Text>
            </Row>
          )}
        </>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 16px;
`;

const LocationName = styled.div`
  display: flex;
  gap: 4px;
  flex-direction: column;
`;
