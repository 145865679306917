import {
  TicketQuestionExpectingChoices,
  TicketQuestionExpectingMenuChoices,
  TicketQuestionExpectingText,
} from 'generated';
import { QuestionContextProvider } from 'components/ServiceRequest/components/QuestionTypes/contexts/QuestionContext';

import {
  Answers,
  GetMeetingServiceByIdForServiceRequestQuestions,
} from '../types/ServiceRequestTypes';
import { Dispatch, SetStateAction } from 'react';
import { QuestionSelect } from './QuestionTypes/Components/QuestionSelect';
import { LongAnswer } from './QuestionTypes/Components/LongAnswer';
import { ShortAnswer } from './QuestionTypes/Components/ShortAnswer';

export const ServiceQuestions = ({
  serviceQuestions,
  answers,
  setAnswers,
  setErrors,
  errors,
}: {
  serviceQuestions: GetMeetingServiceByIdForServiceRequestQuestions;
  answers: Answers;
  setAnswers: Dispatch<SetStateAction<Answers>>;
  setErrors: Dispatch<SetStateAction<boolean>>;
  errors: boolean;
}) => {
  const renderQuestion = (
    question:
      | TicketQuestionExpectingChoices
      | TicketQuestionExpectingMenuChoices
      | TicketQuestionExpectingText
  ) => {
    switch (question.__typename) {
      case 'TicketQuestionExpectingChoices':
      case 'TicketQuestionExpectingMenuChoices': {
        return <QuestionSelect key={question.id} />;
      }
      case 'TicketQuestionExpectingText': {
        const TextAnswer = question.multilineAnswer ? LongAnswer : ShortAnswer;
        return <TextAnswer />;
      }
      default:
        return null;
    }
  };

  return (
    <>
      {serviceQuestions.map((question) => {
        return (
          <QuestionContextProvider
            key={question.id}
            question={question}
            answers={answers}
            hasError={errors}
            setHasError={setErrors}
            setAnswers={setAnswers}
          >
            {renderQuestion(question)}
          </QuestionContextProvider>
        );
      })}
    </>
  );
};
