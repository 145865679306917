import { Body } from '@robinpowered/design-system';
import styled from '@emotion/styled';
import { Avatar } from '@robinpowered/ui-kit';
import { getInitials } from 'lib/utility';

type Props = {
  users:
    | (
        | {
            __typename?: 'User';
            id: string;
            name?: string | null;
            avatar?: string | null;
          }
        | null
        | undefined
      )[]
    | undefined;
};

export const UsersCell = ({ users }: Props) => {
  if (!users || !users[0]) {
    return null;
  }

  if (users.length === 1) {
    const user = users[0];
    return (
      <UserContainer>
        {user.avatar ? (
          <Avatar size={'small'} src={user.avatar} />
        ) : (
          <AvatarText>
            <Body.XSmall>{getInitials(user.name || '')}</Body.XSmall>
          </AvatarText>
        )}

        <Body.Small>{user.name}</Body.Small>
      </UserContainer>
    );
  }

  const avatarList = users.slice(0, 3).map((u) => {
    if (u?.avatar) {
      return <Avatar key={u.id} size={'small'} src={u.avatar} />;
    }
    return (
      <AvatarText key={u?.id}>
        <Body.XSmall>
          {users[0]?.name
            ?.split(' ')
            .map((word) => word.charAt(0))
            .join('')}
        </Body.XSmall>
      </AvatarText>
    );
  });

  return (
    <UserContainer>
      <Avatar.Group size={'small'} max={{ count: 3 }}>
        {avatarList}
      </Avatar.Group>
    </UserContainer>
  );
};

const UserContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const AvatarText = styled.div`
  display: flex;
  background-color: #f0f0f0;
  box-sizing: border-box;
  color: #fff;
  position: relative;
  justify-content: center;
  align-items: center;
  background: rgba(72, 71, 71, 0.25);
  width: 24px;
  height: 24px;
  border-radius: 100%;
`;
