import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { Body, Colors, RadioGroup } from '@robinpowered/design-system';
import { Checkbox, InputNumber, theme } from '@robinpowered/ui-kit';
import {
  SelectType,
  calcLogarithmicGrowth,
} from 'components/ServiceRequest/types/ServiceRequestTypes';
import { TicketQuestionMenuOptionInput } from 'generated';
import { formatCurrency } from 'lib/utility';
import { useTranslation } from 'react-i18next';

type MeetingServicesProps = {
  selectType: SelectType;
  option: { id: string } & TicketQuestionMenuOptionInput;
  selected: boolean;
  onSelectOption: (quantity: number | null) => void;
  onUpdateQuantity: (quantity: number) => void;
  index: number;
  quantity: number;
};

export const BaseOption = ({
  selectType,
  option,
  selected,
  index,
  onSelectOption,
  onUpdateQuantity,
  quantity,
}: MeetingServicesProps) => {
  const { t } = useTranslation('MeetingServiceRequest');
  const { useToken } = theme;
  const { token } = useToken();
  return (
    <>
      <OptionContainer
        index={index}
        tabIndex={0}
        selected={selected}
        activeColor={token.Input?.activeBorderColor || '#000'}
        hoverColor={token.Input?.hoverBorderColor || '#000'}
        onKeyPress={(e) => {
          if (e.key === 'Enter' && !(e.target instanceof HTMLInputElement))
            onSelectOption(quantity);
        }}
        onClick={(e) => {
          if (!(e.target instanceof HTMLInputElement)) onSelectOption(quantity);
        }}
      >
        {selectType === 'multi' ? (
          <Checkbox
            checked={selected}
            onChange={() => onSelectOption(quantity)}
          />
        ) : (
          <RadioGroup
            value={selected ? 0 : 1}
            options={[{ label: '', value: 0 }]}
            onChange={() => onSelectOption(quantity)}
          />
        )}
        <OptionInfoWrapper>
          {option.imageUrl && (
            <ImageContainer>
              <img
                src={option.imageUrl}
                loading="lazy"
                alt={option.name}
                style={{ maxWidth: '100%', maxHeight: '100%' }}
              />
            </ImageContainer>
          )}

          <OptionInfo>
            <Body.Small>
              {option.name || t('select_answer.default_option_name', { index })}
            </Body.Small>
            {option.description && (
              <Body.Small style={{ color: Colors.Gray60 }}>
                {option.description}
              </Body.Small>
            )}
          </OptionInfo>
        </OptionInfoWrapper>
        {option.quantifiable && selected && (
          <div
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <Body.Small>{t('select_answer.quantity')}</Body.Small>
            <InputNumber
              onChange={(val) => {
                if (val) {
                  onUpdateQuantity(val);
                }
              }}
              min={1}
              max={999}
              defaultValue={quantity}
              style={{ width: '65px' }}
            />
          </div>
        )}

        {option.unitPrice && (
          <>
            <Body.Small color={Colors.Gray60}>
              {formatCurrency(option.unitPrice)}
            </Body.Small>
          </>
        )}
      </OptionContainer>
    </>
  );
};

const fadeDown = keyframes`
  from {
    opacity: 0;
    top: -30px;
  }
  to {
    opacity: 1;
    top: 0px;
  }
`;

const OptionContainer = styled.div<{
  selected: boolean;
  index: number;
  activeColor: string;
  hoverColor: string;
}>`
  display: flex;
  flex-direction: row;
  border-radius: 4px;
  border: 1px solid
    ${({ selected, theme, activeColor }) =>
      selected ? activeColor : theme.colorTokens.stroke.layer2};
  gap: 16px;
  align-items: center;
  padding: 16px;
  cursor: pointer;
  min-height: 90px;

  &:hover {
    border: 1px solid ${({ hoverColor }) => hoverColor};
  }
  margin-bottom: 12px;

  animation: ${fadeDown};
  animation-duration: ${(props) =>
    /* We want to delay the animation on subsequent components */
    calcLogarithmicGrowth(0.2, 0.5, 0.8, props.index + 1)}s;
  animation-timing-function: cubic-bezier(0.65, -0.25, 0.38, 1.19);
  animation-fill-mode: forwards;
`;

const OptionInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  width: 100%;
`;

const OptionInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

const ImageContainer = styled.div`
  display: flex;
  gap: 10px;
  border-radius: 4px;
  width: fit-content;
  align-items: center;
  justify-content: center;
  margin-top: 8px;
  width: 88px;
  height: 88px;
  position: relative;
`;
