import { Body } from '@robinpowered/design-system';
import { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import moment from 'moment';
import { ServiceActionButtons } from '../components/ServiceActionButtons';
import { MeetingServiceByIdType } from '../contexts/ServicesTableContext';
import { TableColumnsType } from '@robinpowered/ui-kit';
import { useMeetingServicesContext } from 'pages/MeetingServicePage/contexts/ServicesContext';
import { ListMeetingServicesSortByCriteria, SortOrder } from 'generated';
import { Assignee } from 'components/common/TableCells/Assignee';

export type TableDataType = {
  key: string;
  name: JSX.Element;
  category: string;
  spaces: string;
  assignee: JSX.Element;
  published: string;
};

// @TODO: Need to export these from ant for proper table typing
// type OnChange = {
//   onChange?: (
//     pagination: TablePaginationConfig,
//     filters: Record<string, FilterValue | null>,
//     sorter: SorterResult<RecordType> | SorterResult<RecordType>[],
//     extra: TableCurrentDataSource<RecordType>
//   ) => void;
// };

export const useManageMeetingServicesTable = (
  meetingServicesById: MeetingServiceByIdType,
  setMeetingServicesById: Dispatch<SetStateAction<MeetingServiceByIdType>>,
  filters: {
    category: { text: string; value: string }[];
    assignee: { text: string; value: string; type: 'User' | 'Group' }[];
  }
) => {
  const { t } = useTranslation('MeetingServicePage');
  const { filtersForMeetingServicesQuery, sortByForMeetingServicesQuery } =
    useMeetingServicesContext();
  const meetingServices = useMemo(() => {
    return [...meetingServicesById.values()].map(
      (service) => service.meetingService
    );
  }, [meetingServicesById]);

  // From AntDesign table docs: please try to leave one column at least without width to fit fluid layout
  const columns: TableColumnsType = [
    {
      title: t('table.columns.name'),
      dataIndex: 'name',
      key: 'name',
      sorter: true,
      sortOrder:
        sortByForMeetingServicesQuery?.criteria ===
        ListMeetingServicesSortByCriteria.ServiceName
          ? sortByForMeetingServicesQuery?.order === SortOrder.Ascending
            ? 'ascend'
            : 'descend'
          : undefined,
    },
    {
      title: t('table.columns.category'),
      dataIndex: 'category',
      key: 'category',
      sorter: true,
      sortOrder:
        sortByForMeetingServicesQuery?.criteria ===
        ListMeetingServicesSortByCriteria.CategoryName
          ? sortByForMeetingServicesQuery?.order === SortOrder.Ascending
            ? 'ascend'
            : 'descend'
          : undefined,
      filters: filters.category,
      defaultFilteredValue:
        filtersForMeetingServicesQuery?.belongsToAnyCategoryId,
    },
    {
      title: t('table.columns.spaces'),
      dataIndex: 'spaces',
      key: 'spaces',
    },
    {
      title: t('table.columns.assignee'),
      dataIndex: 'assignee',
      key: 'assignee',
      filterSearch: true,
      filters: filters.assignee,
      onFilter: () => true,
      defaultFilteredValue: [
        ...(filtersForMeetingServicesQuery?.assignedToAny?.groupIds.map(
          (id) => id
        ) || []),
        ...(filtersForMeetingServicesQuery?.assignedToAny?.userIds.map(
          (id) => id
        ) || []),
      ],
    },
    {
      title: t('table.columns.published'),
      dataIndex: 'published',
      key: 'published',
      sorter: true,
      sortOrder:
        sortByForMeetingServicesQuery?.criteria ===
        ListMeetingServicesSortByCriteria.UpdatedAt
          ? sortByForMeetingServicesQuery?.order === SortOrder.Ascending
            ? 'ascend'
            : 'descend'
          : undefined,
      width: 175,
    },
    {
      title: t('table.columns.action'),
      dataIndex: 'action',
      key: 'action',
      width: 125,
      fixed: 'right',
    },
  ];

  const [showConfirmDelete, setShowConfirmDelete] = useState(false);

  const tableData = useMemo(() => {
    return (
      meetingServices?.map((service) => {
        const spaceCount =
          service.availabilityInBuildings.buildingAvailability.reduce(
            (acc, curr) => {
              return acc + curr.spaceCount;
            },
            0
          );

        return {
          key: service.id,
          name: <ServiceName title={service.name}>{service.name}</ServiceName>,
          category: service.category.name,
          spaces: `${t(`table.rows.spaces`, { count: spaceCount })}`,
          assignee: <Assignee assignees={service.assignees} />,
          published: moment(service.updatedAt).format('MMM D, YYYY'),
          action: (
            <ServiceActionButtons
              serviceId={service.id}
              canDelete={service.permissions?.canDelete}
              canUpdate={service.permissions?.canUpdate}
            />
          ),
        };
      }) || []
    );
  }, [meetingServices, t]);

  return {
    tableData,
    columns,
    showConfirmDelete,
    setShowConfirmDelete,
    setMeetingServicesById,
  };
};

const ServiceName = styled(Body.Small)`
  max-width: 350px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
`;
