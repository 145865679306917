import { Input, Modal } from '@robinpowered/ui-kit';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { useTicketsListPageContext } from './TicketsListPageContext';

const { TextArea } = Input;
export const RejectCommentModal = () => {
  const {
    selectedTicketIdForApprovalProcess,
    handleRejectMeetingServiceRequest,
    approvalProcessing,
    setSelectedTicketIdForApprovalProcess,
    ticketById,
  } = useTicketsListPageContext();
  const [modalOpen, setModalOpen] = useState(true);
  const { t } = useTranslation('TicketsListPage');
  const [comment, setComment] = useState('');

  if (!selectedTicketIdForApprovalProcess) {
    return null;
  }

  const selectedTicketForRejection = ticketById.get(
    selectedTicketIdForApprovalProcess.id
  );

  if (!selectedTicketForRejection) {
    return null;
  }

  const title = selectedTicketForRejection?.regardsEventAtSpace.event?.title
    ? t(`approvals.comment.title`, {
        serviceName: selectedTicketForRejection.meetingService.name,
        eventTitle:
          selectedTicketForRejection?.regardsEventAtSpace.event?.title,
        key: selectedTicketForRejection?.key,
      })
    : t(`approvals.comment.title_no_event`, {
        serviceName: selectedTicketForRejection?.meetingService.name,
        key: selectedTicketForRejection?.key,
      });
  return (
    <ModalComponent>
      <Modal
        destroyOnClose
        title={title}
        open={modalOpen}
        onOk={() => {
          handleRejectMeetingServiceRequest({
            latestChangeId: selectedTicketForRejection?.latestChangeId,
            meetingServiceRequestId: selectedTicketForRejection?.id,
            comment,
          });
        }}
        confirmLoading={approvalProcessing}
        onCancel={() => {
          setModalOpen(false);
        }}
        afterClose={() => {
          setSelectedTicketIdForApprovalProcess(null);
        }}
        style={{ padding: 0 }}
        cancelText={t('approvals.comment.cancel')}
        okText={t('approvals.comment.reject')}
        okType={'danger'}
        styles={{
          content: {
            padding: '24px 0',
          },
          header: {
            padding: '0 24px',
            paddingRight: '40px',
            bottom: '10px',
            position: 'relative',
          },
          footer: {
            padding: '0 24px',
          },
        }}
      >
        <Body>
          <label>
            {t('approvals.comment.label')}{' '}
            <span style={{ opacity: 0.5 }}>
              {t('approvals.comment.optional')}
            </span>
          </label>
          <TextArea
            rows={4}
            placeholder={t('approvals.comment.placeholder')}
            maxLength={2000}
            style={{ marginTop: '8px' }}
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          />
        </Body>
      </Modal>
    </ModalComponent>
  );
};

const ModalComponent = styled.div`
  .ant-modal-wrap {
    .ant-modal-content {
      .ant-modal-close {
        position: relative;
      }
    }
  }
`;

const Body = styled.div`
  border-top: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
  padding: 24px 24px;
`;
