import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { Select } from 'components/ServiceForm/components/Select/Select';
import { useRequestingServiceContext } from '../contexts/RequestingServiceContext';

export const SelectCategory = () => {
  const { t } = useTranslation('MeetingServiceRequest');

  const { selectCategory, selectedCategory, categories } =
    useRequestingServiceContext();

  return (
    <StyledAddWrapper>
      <Select
        name={t(`select_category.label`)}
        label={t(`select_category.label`)}
        value={selectedCategory}
        placeholder={t(`select_category.label`)}
        options={categories}
        onSelect={(category) => {
          selectCategory({
            value: category.value,
            label: category.label,
            id: category.id,
          });
        }}
      />
    </StyledAddWrapper>
  );
};

const StyledAddWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;
