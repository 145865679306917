import styled from '@emotion/styled';
import { Body, Colors, Flex, Heading } from '@robinpowered/design-system';
import { QuestionBuilderList } from 'components/ServiceForm/sections/QuestionsSection/QuestionBuilder';
import { useTranslation } from 'react-i18next';
import { useManageQuestions } from './QuestionBuilder/hooks/useManageQuestions';
import { AddItemButton } from 'components/ServiceForm/components/AddItemButton/AddItemButton';
import { ServiceFormContainerWidths } from 'styles';
import { useFormContext } from 'react-hook-form';
import { MeetingServiceType } from 'components/ServiceForm/ServiceFormContainer';

const MAX_QUESTIONS = 50;

export const QuestionsSection = () => {
  const { t } = useTranslation('QuestionsSection');
  const { getValues } = useFormContext<MeetingServiceType>();
  const { handleAddQuestion } = useManageQuestions();

  return (
    <QuestionsWrapper>
      <Flex maxWidth={ServiceFormContainerWidths} width={'100%'}>
        <Header>
          <Flex display="flex" flexDirection="row" alignItems="end">
            <Heading.Medium style={{ marginRight: '8px' }}>
              {t('sections.question.title')}
            </Heading.Medium>
            <Body.Small style={{ color: Colors.Gray60 }}>
              {t('sections.question.optional')}
            </Body.Small>
          </Flex>
          <Body.Regular style={{ color: Colors.Gray80 }}>
            {t('sections.question.subtitle')}
          </Body.Regular>
        </Header>

        <QuestionsBuilderWrapper>
          <QuestionBuilderList />
          {getValues('questions').size < MAX_QUESTIONS && (
            <AddItemButton
              onClick={handleAddQuestion}
              title={t('add.button')}
              ariaLabel={t('add.label')}
            />
          )}
        </QuestionsBuilderWrapper>
      </Flex>
    </QuestionsWrapper>
  );
};

const QuestionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 80px auto 40px;
`;

const Header = styled.div`
  background-color: ${Colors.White0};
  padding: 24px;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colorTokens.stroke.layer2};
`;

const QuestionsBuilderWrapper = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  margin: 16px 0px;
  gap: 16px;
`;
