import { Button } from '@robinpowered/ui-kit';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { useServiceDetailsSidebarContext } from '../contexts/ServiceDetailsSidebarContext';
import Close from '@robinpowered/ui-kit-icons/Close';
import Checkmark from '@robinpowered/ui-kit-icons/Checkmark';
import { useTicketsListPageContext } from 'pages/TicketsListPage/contexts/TicketsListPageContext';

export const FooterButtons = () => {
  const { t } = useTranslation('ServiceDetailsSidebar');
  const { serviceDetails, loading, userCanApprove } =
    useServiceDetailsSidebarContext();
  const {
    handleApproveMeetingServiceRequest,
    setSelectedTicketIdForApprovalProcess,
    approvalProcessing,
    selectedTicketIdForApprovalProcess,
  } = useTicketsListPageContext();

  const isProcessingApproval =
    approvalProcessing &&
    selectedTicketIdForApprovalProcess?.id === serviceDetails?.id;

  /**
   * Potential flows:
   * Approval/Rejection flow
   * Edit Ticket/Cancel ticket flow
   * Reopen ticket flow
   * Edit event flow
   */

  switch (true) {
    case userCanApprove && !!serviceDetails:
      return (
        <ButtonGroup>
          <Button
            loading={loading || isProcessingApproval}
            type="primary"
            onClick={() => {
              setSelectedTicketIdForApprovalProcess({
                id: serviceDetails.id,
                status: 'approving',
              });
              handleApproveMeetingServiceRequest({
                latestChangeId: serviceDetails.latestChangeId,
                meetingServiceRequestId: serviceDetails.id,
              });
            }}
            icon={<Checkmark />}
          >
            {t('footer.approval.approve')}
          </Button>
          <Button
            loading={loading || isProcessingApproval}
            danger
            onClick={() =>
              setSelectedTicketIdForApprovalProcess({
                id: serviceDetails.id,
                status: 'rejecting',
              })
            }
            icon={<Close />}
          >
            {t('footer.approval.reject')}
          </Button>
        </ButtonGroup>
      );

    default:
      return null;
  }
};

const ButtonGroup = styled.div`
  display: flex;
  gap: 8px;
`;
