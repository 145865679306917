// Ant design tables do not horizontally scale properly
// https://github.com/ant-design/ant-design/issues/23974
import { RefObject, useLayoutEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

type Params = {
  headerHeight: number;
  footerHeight: number;
};

export const useFixAntTableHeight = (
  ref: RefObject<Element>,
  params: Params
) => {
  // Keep the Table the height of the parent.
  const [tableHeight, setTableHeight] = useState<number>();
  const resizeTable = useDebouncedCallback(
    () => {
      const node = ref.current;
      if (!node) {
        return;
      }
      const { height } = node.getBoundingClientRect();
      // height of the content minus the header and footer
      setTableHeight(height - params.headerHeight - params.footerHeight);
    },
    100,
    {
      trailing: true,
      maxWait: 100,
    }
  );

  useLayoutEffect(() => {
    resizeTable();
    window.addEventListener('resize', resizeTable);

    return () => {
      window.removeEventListener('resize', resizeTable);
    };
  }, [resizeTable]);

  return tableHeight;
};
