import {
  GetMeetingServiceAvailableInSpaceQuery,
  GetMeetingServiceByIdForServiceRequestQuery,
  GetMeetingServiceRequestByIdForEditingServiceRequestQuery,
} from 'generated';

export type SelectType = 'multi' | 'single';

type QuestionId = string;
type OptionId = string;
export type Options = Map<OptionId, { quantity: number | null }>;
export type TextAnswer = { type: 'text'; text: string };
export type ChoicesAnswer = { type: 'choices'; options: Options };
export type Unanswered = { type: 'unanswered' };
export type Answer = TextAnswer | ChoicesAnswer | Unanswered;
export type Answers = Map<QuestionId, Answer>;

/* Calculates a value that increases but as a decreasing rate as the dynamicValue increases  */
export const calcLogarithmicGrowth = (
  staticValue: number,
  plateau: number,
  speed: number,
  dynamicValue: number
) => {
  return staticValue + plateau * Math.log(1 + speed * dynamicValue);
};

export type selectedServiceWithQuestions =
  | GetMeetingServiceByIdForServiceRequestQuery['getMeetingServiceById']['meetingService']
  | undefined;

export type GetMeetingServiceByIdForServiceRequestQuestions =
  NonNullable<selectedServiceWithQuestions>['questions'];

export type GetMeetingServiceByIdForServiceRequestAssignees =
  NonNullable<selectedServiceWithQuestions>['assignees'];

export type GetMeetingServiceByIdForServiceRequestApprovers =
  NonNullable<selectedServiceWithQuestions>['approvers'];

export type MeetingServiceEvent = {
  locationName: string;
  dueDate: string;
  numOfAttendees: number;
};

export type MeetingService =
  GetMeetingServiceAvailableInSpaceQuery['listMeetingServicesAvailableInSpace']['meetingServices'][0];

export type ChangeAnswersMeetingServiceRequest =
  // eslint-disable-next-line max-len
  GetMeetingServiceRequestByIdForEditingServiceRequestQuery['getMeetingServiceRequestByIdentifier']['meetingServiceRequest'];

export type ChangeAnswersMeetingServiceRequestService =
  | NonNullable<ChangeAnswersMeetingServiceRequest>['meetingService']
  | undefined;

export type ChangeAnswersMeetingServiceRequestQuestions =
  NonNullable<ChangeAnswersMeetingServiceRequestService>['questions'];

export type ChangeAnswersMeetingServiceRequestAssignees =
  NonNullable<ChangeAnswersMeetingServiceRequestService>['assignees'];
