import { TextInput } from '@robinpowered/design-system';
import { useTranslation } from 'react-i18next';
import { QuestionTypeContainer } from './QuestionTypeContainer';
import { useTextAnswer } from '../hooks/useTextAnswer';
import { useQuestionContext } from 'components/ServiceRequest/components/QuestionTypes/contexts/QuestionContext';

export const ShortAnswer = () => {
  const { t } = useTranslation('MeetingServiceRequest');
  const { prompt, required } = useQuestionContext();
  const { answer, handleChange, error } = useTextAnswer('short');

  return (
    <QuestionTypeContainer title={prompt} required={required}>
      <TextInput
        error={error}
        name={t('short_answer.label')}
        value={answer && answer.type === 'text' ? answer.text : ''}
        placeholder={t('short_answer.placeholder')}
        onChange={(value) => handleChange(value, required)}
      />
    </QuestionTypeContainer>
  );
};
