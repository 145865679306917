import { ButtonV4 } from '@robinpowered/design-system';
import { Avatar } from '@robinpowered/ui-kit';
import { ChevronDownOutline, ChevronRightOutline } from '@robinpowered/icons';
import styled from '@emotion/styled';
import { IAssignees, useAssignees } from './useAssignees';
import { useState } from 'react';
import { Assignee } from './components/Assignee';

export const Assignees = ({
  assignees: assigneesParam,
}: {
  assignees: IAssignees;
}) => {
  const [open, setIsOpen] = useState(false);
  const { avatarStack, parsedAssignees } = useAssignees(assigneesParam);

  const renderAssignee = () => {
    if (avatarStack.length === 1) {
      if (parsedAssignees?.groups.length === 1) {
        const group = parsedAssignees.groups[0];
        return (
          <Assignee
            avatar={group.groupName || ''}
            name={group.groupName || ''}
            label={`${group.groupName} (${group.members.length})`}
          />
        );
      }

      if (parsedAssignees?.users.length === 1) {
        const user = parsedAssignees?.users[0];
        const displayName = user.userName
          ? user.userName
          : user.primaryEmail?.email || '';

        return (
          <Assignee
            avatar={user.avatar || ''}
            name={displayName}
            email={user.primaryEmail?.email}
            label={displayName}
          />
        );
      }
    }

    return (
      <Avatar.Group size={'small'} max={{ count: 3 }}>
        {avatarStack.map((avatar) => (
          <Avatar
            key={avatar.id}
            src={avatar.src}
            name={avatar.name}
            size={26}
          />
        ))}
      </Avatar.Group>
    );
  };
  const renderGroups = () => {
    return parsedAssignees?.groups.map((group) => {
      return (
        <GroupWrapper key={group.id}>
          <Assignee
            avatar={group.groupName || ''}
            name={group.groupName || ''}
            label={`${group.groupName} (${group.members.length})`}
          />

          <MembersList>
            {group.members.map((member) => {
              return (
                <AssigneeWrapper key={member.id}>
                  <Assignee
                    avatar={member.avatar || ''}
                    name={
                      member.name
                        ? member.name
                        : member.primaryEmail?.email || ''
                    }
                    email={member.primaryEmail?.email}
                    label={
                      member.name
                        ? member.name
                        : member.primaryEmail?.email || ''
                    }
                  />
                </AssigneeWrapper>
              );
            })}
          </MembersList>
        </GroupWrapper>
      );
    });
  };

  const renderUsers = () => {
    return parsedAssignees?.users.map((user) => {
      return (
        <AssigneeWrapper key={user.id}>
          <Assignee
            avatar={user.avatar || ''}
            name={user.userName || ''}
            email={user.primaryEmail?.email}
            label={
              user.userName ? user.userName : user.primaryEmail?.email || ''
            }
          />
        </AssigneeWrapper>
      );
    });
  };

  return (
    <Wrapper>
      {/* Only show the dropdown button if it is a single group or multi assignees */}
      {!(avatarStack.length === 1 && parsedAssignees?.users.length === 1) && (
        <ButtonV4
          icon={open ? ChevronDownOutline : ChevronRightOutline}
          size="xSmall"
          variant="tertiary"
          onClick={() => setIsOpen((prev) => !prev)}
        />
      )}

      {!open ? (
        renderAssignee()
      ) : (
        <AssigneeList>
          {renderGroups()}
          {renderUsers()}
        </AssigneeList>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
`;
const AssigneeList = styled.ul`
  display: flex;
  gap: 8px;
  flex-direction: column;
`;

const AssigneeWrapper = styled.li`
  display: flex;
  gap: 8px;
  align-items: center;
`;

const GroupWrapper = styled.li`
  display: flex;
  gap: 8px;
  flex-direction: column;
`;

const MembersList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-left: 24px;
`;
