import styled from '@emotion/styled';
import { Avatar, Typography } from '@robinpowered/ui-kit';

export const Assignee = ({
  name,
  avatar,
  label,
  email,
}: {
  name: string;
  email?: string;
  avatar: string;
  label: string;
}) => {
  return (
    <AssigneeWrapper>
      <Avatar src={avatar} name={name} size={26} />
      <AssigneeInfo>
        <Typography.Text>{label}</Typography.Text>
        {email && (
          <Typography.Text color="textSecondary">{email}</Typography.Text>
        )}
      </AssigneeInfo>
    </AssigneeWrapper>
  );
};

const AssigneeWrapper = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: row;
  align-items: center;
`;

const AssigneeInfo = styled.div`
  display: flex;
  flex-direction: column;
`;
